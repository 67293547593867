import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { WebSocketService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import {
  DeviceMessage,
  DeviceNotify,
  IccRegistrationState,
  IccState,
  isDeviceNotify,
} from '@liveconnect/shared/models';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface MonitoredDevice {
  connected$: BehaviorSubject<boolean>;
  registrationState$: BehaviorSubject<IccRegistrationState>;
  state$: BehaviorSubject<IccState>;
}

@Injectable({ providedIn: 'root' })
export class DeviceMonitorService {
  private logger = new Logger(this.constructor.name);
  private monitoredDevices: Map<string, MonitoredDevice> = new Map();

  private websocket?: Subject<DeviceMessage>;

  private iccWebsocketUrl = inject(ENVIRONMENT).iccWebsocketUrl;

  constructor(
    private oauthStorage: OAuthStorage,
    private webSocketService: WebSocketService,
  ) {}

  public isConnected(uuid: string): Observable<boolean> {
    return this.getMonitoredDevice(uuid).connected$.asObservable();
  }

  public getDeviceState(uuid: string): Observable<IccRegistrationState> {
    return this.getMonitoredDevice(uuid).registrationState$.asObservable();
  }

  public getIccState(uuid: string): Observable<IccState> {
    return this.getMonitoredDevice(uuid).state$.asObservable();
  }

  private getMonitoredDevice(uuid: string): MonitoredDevice {
    let monitoredDevice = this.monitoredDevices.get(uuid);
    if (!monitoredDevice) {
      this.watchDevice(uuid);
      monitoredDevice = {
        connected$: new BehaviorSubject(false),
        registrationState$: new BehaviorSubject(IccRegistrationState.UNKNOWN),
        state$: new BehaviorSubject(IccState.VOID),
      };
      this.monitoredDevices.set(uuid, monitoredDevice);
    }
    return monitoredDevice;
  }

  private watchDevice(uuid: string) {
    if (!this.iccWebsocketUrl) {
      return;
    }

    const accessToken = this.oauthStorage.getItem('access_token') ?? undefined;
    this.initWebsocket();

    if (!this.websocket) {
      throw new Error('Unable to create websocket');
    }

    this.websocket.next({
      action: 'WATCH_DEVICE',
      payload: {
        accessToken,
        deviceUuid: uuid,
      },
    });
  }

  private stopMonitoringDevice(uuid: string) {
    this.monitoredDevices.delete(uuid);
    this.logger.info('Stopped monitoring device [%s]', uuid);
  }

  private initWebsocket(): void {
    if (this.websocket) {
      return;
    }

    if (!this.iccWebsocketUrl) {
      return;
    }

    this.websocket = this.webSocketService.connect<DeviceMessage>(this.iccWebsocketUrl, true);

    this.websocket.pipe().subscribe({
      next: (msg: DeviceMessage) => {
        if (isDeviceNotify(msg)) {
          const device = this.monitoredDevices.get(msg.payload.deviceUuid);
          if (!device) {
            this.logger.error(
              'Received notification for unmonitored device',
              msg.payload.deviceUuid,
            );
            return;
          }

          this.handleNotify(device, msg);
        }
      },
      error: (err: Error) => {
        this.logger.error('Websocket error', err);
        this.monitoredDevices.forEach(device => {
          device.connected$.error(err);
          device.registrationState$.error(err);
        });
        this.monitoredDevices.clear();
        if (this.websocket) {
          this.websocket.unsubscribe();
          this.websocket = undefined;
        }
      },
      complete: () => {
        this.logger.info('Websocket closed');
        this.websocket = undefined;
        if (this.observedDeviceExists()) {
          this.logger.info('Devices are actively monitored, reopening websocket');
          this.watchObservedDevices();
        } else {
          this.monitoredDevices.clear();
        }
      },
    });
  }

  private isDeviceObserved(device: MonitoredDevice): boolean {
    return device.connected$.observed || device.registrationState$.observed;
  }

  private observedDeviceExists(): boolean {
    for (const device of this.monitoredDevices.values()) {
      if (this.isDeviceObserved(device)) {
        return true;
      }
    }
    return false;
  }

  private watchObservedDevices() {
    for (const [uuid, device] of this.monitoredDevices) {
      if (this.isDeviceObserved(device)) {
        this.watchDevice(uuid);
      } else {
        this.stopMonitoringDevice(uuid);
      }
    }
  }

  private handleNotify(device: MonitoredDevice, message: DeviceNotify) {
    switch (message.note) {
      case 'DEVICE_CONNECTED':
      case 'DEVICE_DISCONNECTED':
        this.handleConnectNotify(device, message);
        this.handleStateNotify(device, message);
        break;
      case IccRegistrationState.UNREGISTERED:
      case IccRegistrationState.IN_SOFTAPPROVAL:
      case IccRegistrationState.REGISTERED:
      case IccRegistrationState.PEERED:
        this.handleStateNotify(device, message);
        this.handleRegistrationStateNotify(device, message);
        break;
      default:
      // do nothing and relaxe 8-)
    }
  }

  private handleStateNotify(device: MonitoredDevice, message: DeviceNotify) {
    let newState: IccState | undefined;
    switch (message.note) {
      case 'DEVICE_CONNECTED': {
        newState = IccState.ONLINE;
        break;
      }
      case 'DEVICE_DISCONNECTED': {
        // These messages can be received out-of-order with a DEVICE_DISCONNECTED message, so be sure not to overwrite
        // a VOID state here.
        if (device.state$.value === IccState.VOID) {
          return;
        }

        newState = IccState.OFFLINE;
        break;
      }
      case IccRegistrationState.UNREGISTERED: {
        newState = IccState.VOID;
        break;
      }
      // Ignored:
      // case IccRegistrationState.IN_SOFTAPPROVAL:
      case IccRegistrationState.REGISTERED:
      case IccRegistrationState.PEERED: {
        // These messages can be received out-of-order with a DEVICE_CONNECTED message, so be sure not to overwrite
        // a ONLINE state here.
        if (device.state$.value === IccState.ONLINE) {
          return;
        }

        newState = IccState.OFFLINE;
        break;
      }
    }

    if (undefined === newState) {
      return;
    }

    const changed = device.state$.value !== newState;
    if (changed) {
      device.state$.next(newState);
    }
  }

  private handleConnectNotify(device: MonitoredDevice, message: DeviceNotify) {
    const announcedState = message.note === 'DEVICE_CONNECTED';
    const changed = device.connected$.value !== announcedState;
    if (changed) {
      device.connected$.next(announcedState);
    }
  }

  private handleRegistrationStateNotify(device: MonitoredDevice, message: DeviceNotify) {
    const changed = device.registrationState$.value !== message.note;
    if (changed) {
      device.registrationState$.next(message.note as IccRegistrationState);
    }
  }
}
