import { Component, ElementRef, computed, input, output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss'],
  standalone: true,
  imports: [IconComponent, ButtonModule],
})
export class CalloutComponent {
  readonly severity = input<'success' | 'info' | 'warn' | 'error'>('info');
  readonly closable = input(false);
  readonly shape = computed(() => this.computeShape());

  readonly calloutClosed = output();

  private static readonly shapes = {
    success: 'check_circle',
    info: 'info',
    warn: 'warning',
    error: 'error',
  };

  constructor(private host: ElementRef<HTMLElement>) {}

  close() {
    this.calloutClosed.emit();
    this.host.nativeElement.remove();
  }

  private computeShape() {
    return CalloutComponent.shapes[this.severity()] || 'circle';
  }
}
