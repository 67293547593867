import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@assethub/shared/models';
import { IccConnection, IccDeviceInfo, IccRegistrationState } from '@liveconnect/shared/models';
import { Observable, of, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface RequestTokenResponse {
  deviceUuid: string;
  token: string;
  createdAt: string;
}

@Injectable({ providedIn: 'root' })
export class IccService {
  private deviceInfoCache: { [uuid: string]: IccDeviceInfo } = {};

  private iccUrl = inject(ENVIRONMENT).iccUrl;

  constructor(private httpClient: HttpClient) {}

  public getDeviceInfo(deviceUuid: string): Observable<IccDeviceInfo> {
    if (!this.iccUrl) {
      return of({ deviceUuid, state: IccRegistrationState.UNKNOWN });
    }

    // Short-circuit this request
    if (this.deviceInfoCache[deviceUuid]) {
      return of(this.deviceInfoCache[deviceUuid]);
    }

    return this.httpClient.get<IccDeviceInfo>(`${this.iccUrl}/device/${deviceUuid}`).pipe(
      tap(deviceInfo => {
        // If state is registered, put result into cache - it is unlikely to change in the future
        if (
          deviceInfo.state === IccRegistrationState.REGISTERED ||
          deviceInfo.state === IccRegistrationState.PEERED
        ) {
          this.deviceInfoCache[deviceUuid] = deviceInfo;
        }
      }),
    );
  }

  public getLatestConnections(deviceUuid: string): Observable<IccConnection[]> {
    if (!this.iccUrl) {
      return of([]);
    }
    return this.httpClient
      .get<IccConnection[]>(`${this.iccUrl}/device/${deviceUuid}/connections`)
      .pipe(
        map((value: IccConnection[]): IccConnection[] => {
          value = value.map((input: IccConnection) => {
            if (typeof input.connectedAt === 'string') {
              input.connectedAt = new Date(input.connectedAt);
            }
            if (typeof input.connectedUntil === 'string') {
              input.connectedUntil = new Date(input.connectedUntil);
            }

            if (input.connectedUntil) {
              input.duration = Math.round(
                (input.connectedUntil.getTime() - input.connectedAt.getTime()) / 1000,
              );
            }

            return input;
          });

          return value;
        }),
      );
  }

  public requestPairingToken(
    deviceUuid: string,
    partNumber?: string,
    serialNumber?: string,
  ): Observable<RequestTokenResponse> {
    if (!this.iccUrl) {
      return throwError(() => new Error('ICC not available in this environment'));
    }
    return this.httpClient.put<RequestTokenResponse>(
      `${this.iccUrl}/device/${deviceUuid}/pairing`,
      {
        partNumber,
        serialNumber,
      },
    );
  }

  public releaseDeviceRegistration(deviceUuid: string): Observable<any> {
    if (!this.iccUrl) {
      return throwError(() => new Error('ICC not available in this environment'));
    }
    return this.httpClient.delete(`${this.iccUrl}/device/${deviceUuid}`).pipe(
      tap(() => {
        delete this.deviceInfoCache[deviceUuid];
      }),
    );
  }
}
