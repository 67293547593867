import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ENVIRONMENT, PermissionEntry } from '@assethub/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { CalloutComponent } from '../callout/callout.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-permissions-view',
  templateUrl: './permissions-view.component.html',
  styleUrls: ['./permissions-view.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProgressBarModule,
    TableModule,
    NgClass,
    SharedModule,
    IconComponent,
    CheckboxModule,
    FormsModule,
    ButtonModule,
    CalloutComponent,
    TranslateModule,
  ],
})
export class PermissionsViewComponent implements OnInit {
  @Input() permissionEntries: PermissionEntry[] = [];
  @Input() totalRecords = 0;
  @Input() pageSize = 10;
  @Input() paginatorStart = 0;
  @Input() narrow = false;
  @Input() loading = false;
  @Input() saving = false;
  @Input() isGroupPermission = false;

  @Output() deletePermission = new EventEmitter<PermissionEntry>();
  @Output() togglePermission = new EventEmitter<{
    entry: PermissionEntry;
    permission: 'admin' | 'write';
  }>();
  @Output() lazyLoad = new EventEmitter<TableLazyLoadEvent>();

  public login: string = '';
  private groupManagement = inject(ENVIRONMENT).groupManagement;
  public groupManagementUrl = this.groupManagement
    ? this.groupManagement.baseUrl + '/visible-groups/'
    : undefined;

  constructor(private oauthService: OAuthService) {}

  ngOnInit(): void {
    const claims = this.oauthService.getIdentityClaims();
    this.login = claims['preferred_username'] || claims['email'];
  }

  public onDeletePermission(index: number) {
    index = index % this.pageSize;
    this.deletePermission.emit(this.permissionEntries[index]);
  }

  public onTogglePermission(index: number, permission: 'admin' | 'write') {
    index = index % this.pageSize;
    this.togglePermission.emit({ entry: this.permissionEntries[index], permission });
  }

  public onLazyLoad($event: TableLazyLoadEvent) {
    this.lazyLoad.emit($event);
  }
}
