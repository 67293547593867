import { Routes } from '@angular/router';
import { ErrorComponent } from '@assethub/shared/components';
import { noServiceConsentAvailable, LicenseGuard } from '@assethub/shared/guards';
import { errorResolver } from '@assethub/shared/resolvers';
import { environment } from '@environments/environment';
import { ProductIdComponent } from './open-with/product-id/product-id.component';
import { SelfDiagnosisComponent } from './self-diagnosis/self-diagnosis.component';
import { TrashComponent } from './shared/components/trash-bin/trash.component';
import { AccessibleTreeGuard } from './shared/guards/accessible-tree.guard';
import { WelcomeComponent } from './welcome/welcome.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [noServiceConsentAvailable],
    data: {
      serviceId: environment.digitalServiceCatalogUrl.serviceIds.basic,
      recentAssetFrom: 'uuid',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'liveconnect',
        canActivate: [AccessibleTreeGuard],
        loadChildren: () =>
          import('@liveconnect/destinations/destinations.module').then(m => m.DestinationsModule),
      },
      {
        path: 'mapview',
        children: [
          {
            path: ':uuid',
            redirectTo: '/asset/:uuid/map',
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/asset/map',
          },
        ],
      },
      {
        path: 'maintenance365',
        loadChildren: () =>
          import('@assethub/maintenance-365/maintenance-365.routes').then(m => m.routes),
      },
      {
        path: 'asset',
        loadChildren: () =>
          import('@assethub/asset-tree-view/asset-tree-view.module').then(
            m => m.AssetTreeViewModule,
          ),
      },
      {
        path: 'search',
        canActivate: [AccessibleTreeGuard],
        loadChildren: () =>
          import('./asset-search/asset-search.module').then(m => m.AssetSearchModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@assethub/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'newsfeed',
        loadChildren: () =>
          import('@assethub/release-notes/release-notes.routes').then(m => m.routes),
      },
      {
        path: 'gui-check',
        loadChildren: () => import('@assethub/gui-check/gui-check.routes').then(m => m.routes),
      },
      {
        path: 'error',
        children: [
          {
            path: 'subscription/:subscription',
            component: ErrorComponent,
            resolve: {
              subscription: errorResolver,
            },
            pathMatch: 'full',
          },
          {
            path: ':reason',
            component: ErrorComponent,
            resolve: {
              reason: errorResolver,
            },
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'debug',
        component: SelfDiagnosisComponent,
      },
      {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [LicenseGuard],
        data: {
          license: 'basic',
          serviceId: environment.digitalServiceCatalogUrl.serviceIds.basic,
        },
      },
      {
        path: 'open',
        children: [
          {
            path: 'productid',
            component: ProductIdComponent,
          },
        ],
      },
      {
        path: 'trash-bin',
        component: TrashComponent,
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ],
  },
];
