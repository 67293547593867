<div class="icon"><app-icon *ngIf="icon" [shape]="icon"></app-icon></div>
<div class="label">{{ label }}</div>
<app-tooltip class="info" *ngIf="disabled && reason.length > 0" icon="help_outline">{{
  reason
}}</app-tooltip>
<div class="info" *ngIf="!disabled || reason.length === 0"></div>
<div class="expand"><app-icon shape="chevron_right"></app-icon></div>
<div #menuContent *ngIf="displayMenu" class="menu-content" [ngStyle]="menuContentStyle">
  <div #menuFrame class="menu-frame">
    <div class="menu-canvas">
      <ng-content></ng-content>
    </div>
  </div>
</div>
