import { Injectable } from '@angular/core';
import { TreeService } from './tree.service';
import { MessageService } from 'primeng/api';
import { NotificationService } from './notification.service';
import {
  AssetChanged,
  AssetCloned,
  AssetCreated,
  AssetDeleted,
  AssetMoved,
  NotificationMessage,
  Permission,
  isAssetNotification,
  isPermissionNotification,
} from '../models';
import { Logger } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class TreeEventsConsumerService {
  private logger = new Logger(this.constructor.name);

  constructor(
    private treeService: TreeService,
    private messageService: MessageService,
    private notificationService: NotificationService,
  ) {}

  public initialize(): void {
    /*
     * This method gets called, so this service is required. When it was not required,
     * the class does not get initialized and thus cannot register subscriptions on the
     * NotificationService.
     */
    this.notificationService.notification$.subscribe((msg: NotificationMessage) => {
      if (isAssetNotification(msg)) {
        this.handleAssetEvent(msg);
      }

      if (isPermissionNotification(msg)) {
        this.handleWsPermissionEvent(msg.permission);
      }
    });
  }

  private handleAssetEvent(event: Required<Pick<NotificationMessage, 'asset'>>) {
    this.logger.debug('Incoming asset change event:', event.asset);

    switch (event.asset.action) {
      case 'created': {
        // Nondestructive change, always apply
        return this.handleAssetCreated(event.asset);
      }

      case 'cloned': {
        // Nondestructive change, always apply
        return this.handleAssetCloned(event.asset);
      }

      case 'changed': {
        // Nondestructive change, when not active asset
        return this.handleAssetChanged(event.asset);
      }

      case 'moved': {
        // Nondestructive change when moved within active tree
        return this.handleAssetMoved(event.asset);
      }

      case 'deleted': {
        // Destructive change, when in active tree
        return this.handleAssetDeleted(event.asset);
      }

      default: {
        const unreachableCall = (arg: never) => {
          this.logger.warn('Unsupported message received:', arg);
        };
        unreachableCall(event.asset);
      }
    }
  }

  private handleAssetCreated(asset: AssetCreated) {
    if (undefined === asset.parentUuid) {
      return;
    }

    const parent = this.treeService.findNode(asset.parentUuid);
    if (true !== parent?.childrenLoaded()) {
      return;
    }

    this.treeService.addNode({
      uuid: asset.uuid,
      parentUuid: asset.parentUuid,
      name: asset.customName,
      type: asset.typeId,
      productName: asset.productName,
      productPictureUrl: asset.productPictureUrl,
    });
  }

  private handleAssetCloned(asset: AssetCloned) {
    if (undefined === this.treeService.findNode(asset.parentUuid)) {
      return;
    }

    return this.treeService.insertClonedNode(asset, asset.parentUuid, asset.insertAfterUuid);
  }

  private handleAssetChanged(asset: AssetChanged): void {
    if (undefined === this.treeService.findNode(asset.uuid)) {
      this.logger.debug('Node to update not known, skipping...');
      return;
    }

    if (this.treeService.isActiveAsset(asset.uuid)) {
      this.messageService.add({
        severity: 'info',
        summary: 'toasts.tree.active-asset-changed',
        sticky: true,
        closable: true,
        life: 10000,
      });

      return;
    }

    this.treeService.updateNode({
      uuid: asset.uuid,
      name: asset.customName,
      type: asset.typeId,
      productName: asset.productName,
      productPictureUrl: asset.productPictureUrl,
      imo: asset.imo,
    });
  }

  private handleAssetMoved(asset: AssetMoved): void {
    const movedAsset = this.treeService.findNode(asset.uuid);
    const destination = this.treeService.findNode(asset.destination);

    // Cannot handle unknown node
    if (movedAsset === undefined) {
      if (destination !== undefined) {
        const parent = asset.toStartOfChildren ? destination : destination.parent!;
        if (parent.childrenLoaded()) {
          this.treeService.fetchBranch(asset.uuid).subscribe();
        }
      }
      return;
    }

    if (this.treeService.isActiveAsset(movedAsset.uuid)) {
      this.messageService.add({
        severity: 'info',
        summary: 'toasts.tree.active-asset-moved',
        sticky: false,
        closable: true,
        life: 10000,
      });
      return;
    }

    if (destination === undefined) {
      // Destination *and* source are unknown - just discard this.
      return;
    }

    const sourceTreeActive = this.treeService.isActiveTree(movedAsset.root.uuid);
    const destinationTreeActive = this.treeService.isActiveTree(destination.root.uuid);

    // By default, do nothing
    let applyMove = false;
    let applyToast = false;

    // Source and dest are in the active tree, just apply (it is not visible anyways)
    if (sourceTreeActive && destinationTreeActive) {
      applyMove = true;
    }

    if (sourceTreeActive && !destinationTreeActive) {
      applyMove = false;
      applyToast = true;
    }

    if (!sourceTreeActive) {
      applyMove = true;
      applyToast = false;
    }

    if (applyMove) {
      this.treeService.applyNodeMoved(movedAsset, destination, asset.toStartOfChildren);
    }

    if (applyToast) {
      this.messageService.add({
        severity: 'info',
        summary: 'toasts.tree.some-asset-was-moved',
        sticky: false,
        closable: true,
        life: 10000,
      });
    }
  }

  private handleAssetDeleted(asset: AssetDeleted): void {
    const deletedAsset = this.treeService.findNode(asset.uuid);
    if (undefined === deletedAsset) {
      return;
    }

    if (this.treeService.isActiveTree(deletedAsset.root.uuid)) {
      this.messageService.add({
        severity: 'info',
        summary: 'toasts.tree.some-asset-was-deleted',
        sticky: false,
        closable: true,
        life: 10000,
      });

      return;
    }

    this.treeService.deleteNode(asset.uuid);
  }

  private handleWsPermissionEvent(permissionEvent: Permission): void {
    // Ignore events for other accounts (when account not set, the change targets
    // current account - when account is !== undefined, it is for foreign account).
    // Note: the backend emits different notification for the users.
    if (permissionEvent.account !== undefined) {
      return;
    }

    if (permissionEvent.action === 'revoked') {
      if (!this.treeService.isActiveTree(permissionEvent.uuid)) {
        this.treeService.deleteNode(permissionEvent.uuid);
      }
      return;
    }

    if (permissionEvent.action === 'granted') {
      if (permissionEvent.supersededRootNodes) {
        permissionEvent.supersededRootNodes.forEach(x => {
          this.treeService.deleteNode(x);

          this.messageService.add({
            severity: 'info',
            summary: 'toasts.tree.extended',
            sticky: true,
            closable: true,
            life: 10000,
          });
        });
      }
      this.treeService.loadTree(permissionEvent.uuid).subscribe();
    }
  }
}
