import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { OAuthService } from 'angular-oauth2-oidc';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { Observable, catchError, filter, map } from 'rxjs';
import {
  AssetAASGenerated,
  ENVIRONMENT,
  NotificationMessage,
  isAssetAASNotification,
} from '../models';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AssetAdministrationShellService {
  private logger = new Logger(this.constructor.name);

  private env = inject(ENVIRONMENT);
  private apiUrl = this.env.apiUrl;
  private integrationLayerProxyUrl = this.env.integrationLayerProxyUrl;
  private integrationLayerApiKey = this.env.integrationLayerApiKey;
  private swsApiKey = this.env.swsApiKey;

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private oauth: OAuthService,
  ) {
    this.notificationService.notification$
      .pipe(
        filter(
          notification =>
            isAssetAASNotification(notification) && notification.aas.action === 'aasxFileCreated',
        ),
      )
      .subscribe((msg: NotificationMessage) => this.handleAASGeneratedEvent(msg.aas));
  }

  downloadAssetAdminShell(partNumber: string): Observable<void> {
    const url = `${this.integrationLayerProxyUrl}/aas/aasx/${partNumber}`;
    const fileName = `SICK-${partNumber}.aasx`;
    return this.httpClient
      .get(url, {
        responseType: 'blob',
        observe: 'body',
        headers: {
          Authorization: 'Bearer ' + this.oauth.getAccessToken(),
          'x-api-key': this.integrationLayerApiKey || this.swsApiKey,
        },
      })
      .pipe(
        map(payload => {
          saveAs(payload, fileName);
          return;
        }),
        catchError(error => {
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.asset-administration-shell.download-error',
            life: 10000,
          });
          throw error;
        }),
      );
  }

  exportAssetAdminShell(assetUuid: string): Observable<void> {
    const url = `${this.apiUrl}/asset/${assetUuid}/asset-administration-shell`;
    this.logger.debug(`AASX-file generation starting...`);
    return this.httpClient
      .post(
        url,
        {},
        {
          observe: 'response',
        },
      )
      .pipe(
        map(() => {
          this.logger.debug(`AASX-file generation started`);

          this.messageService.add({
            severity: 'info',
            summary: 'toasts.asset-administration-shell.export-started',
            life: 5000,
          });
        }),
        catchError(err => {
          this.logger.error(`Error while starting aasx-generation: `, err);
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.asset-administration-shell.export-start-error',
            life: 10000,
          });

          throw err;
        }),
      );
  }

  handleAASGeneratedEvent(aasGeneratedEvent: AssetAASGenerated | undefined) {
    if (undefined === aasGeneratedEvent) {
      return;
    }

    this.logger.debug(`AASX-file generation finished, starting download...`);

    this.httpClient
      .get(aasGeneratedEvent.aasxDownloadUrl, {
        responseType: 'blob',
        observe: 'body',
      })
      .subscribe({
        next: payload => {
          const fileName = aasGeneratedEvent.fileName;
          this.logger.debug(`AASX-file received, sending to browser as ${fileName}...`);
          this.messageService.add({
            severity: 'success',
            summary: 'toasts.asset-administration-shell.download-started',
            life: 5000,
          });

          saveAs(payload, fileName);
        },
        error: error => {
          this.logger.error(`Failed to download aasx: `, error);
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.asset-administration-shell.download-error',
            life: 10000,
          });
        },
      });
  }
}
