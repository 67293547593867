<div class="card flex justify-content-center">
  <app-menu #overlay direction="dr" icon="" [showTriangle]="false">
    <div class="search-results">
      <div *ngFor="let group of groups">
        <span class="group-title"> {{ group.label }}</span>
        <div *ngFor="let item of group.items">
          <ng-container [ngSwitch]="item.state">
            <div *ngSwitchCase="'searching'" class="searching">
              <p-skeleton></p-skeleton>
            </div>
            <div *ngSwitchCase="'error'" class="error">
              {{ 'global-search.groups.' + item.content + '.error' | translate }}
            </div>
            <div *ngSwitchCase="'empty'" class="empty">
              {{ 'global-search.groups.' + item.content + '.no-data' | translate }}
            </div>
            <div *ngSwitchDefault class="searchResult" (click)="onSelection(item)">
              <div class="picture">
                <app-asset-icon
                  *ngIf="item.assetImage"
                  [asset]="item.assetImage"
                  size="38"
                ></app-asset-icon>
                <app-icon *ngIf="item.icon" [shape]="item.icon" size="38"></app-icon>
              </div>
              <div class="label">
                <div
                  class="primary"
                  [innerHTML]="item.primaryLabel | highlight: item.keyword"
                ></div>
                <div
                  class="secondary"
                  *ngIf="item.secondaryLabel"
                  [innerHTML]="item.secondaryLabel | highlight: item.keyword"
                ></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </app-menu>
  <div #searchField>
    <app-expandable-search-field
      (valueChange)="searchChanged($event)"
      (expandStateChanged)="expandChanged($event)"
      (searchIconClicked)="searchChanged($event)"
    >
    </app-expandable-search-field>
  </div>
</div>
