import { inject, Injectable } from '@angular/core';
import { AssetItem } from '@assethub/shared/models';
import { TreeService } from '@assethub/shared/services';
import { FEATURE_TOGGLE, Logger } from '@assethub/shared/utils';
import { Subscription } from 'rxjs';
import { IccState } from '../models';
import { isPairable } from '../utils/icc';
import { DeviceMonitorService } from './device-monitor.service';

@Injectable({
  providedIn: 'root',
})
export class AssetTreeConnectionStateService {
  private logger = new Logger(this.constructor.name);

  private currentTreeUuid?: string;
  private monitors = new Map<string, Subscription>();
  private initialized = false;
  private featureToggleActive = inject(FEATURE_TOGGLE);

  constructor(
    private treeService: TreeService,
    private deviceMonitorService: DeviceMonitorService,
  ) {}

  public initialize() {
    if (!this.featureToggleActive('ASSET_TREE_LIVE_STATE')) {
      return;
    }
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.logger.debug('Initializing service');
    this.treeService.selectedTreeChanged.subscribe(uuid => this.refreshTree(uuid));
    this.treeService.treeChanged.subscribe(uuid => this.refreshTree(uuid));
  }

  private refreshTree(uuid: string) {
    if (this.currentTreeUuid !== uuid) {
      this.logger.info(`Detected tree change, releasing ${this.monitors.size} subscriptions...`);
      this.monitors.forEach(subscription => subscription.unsubscribe());
      this.monitors.clear();
    }

    this.currentTreeUuid = uuid;
    const tree = this.treeService.getTreeByUuid(uuid);

    if (tree === undefined) {
      return;
    }

    this.subscribeRecursive(tree);
    this.logger.info(`Currently monitoring ${this.monitors.size} devices`);
  }

  private subscribeRecursive(node: AssetItem) {
    if (isPairable(node.type) && !this.monitors.has(node.uuid)) {
      this.monitors.set(
        node.uuid,
        this.deviceMonitorService.getIccState(node.uuid).subscribe(state => {
          if (state === IccState.VOID) {
            node.isOnline = undefined;
            return;
          }

          node.isOnline = state === IccState.ONLINE;
        }),
      );
    }

    for (const child of node.children) {
      this.subscribeRecursive(child);
    }
  }
}
