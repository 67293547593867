import { NgIf } from '@angular/common';
import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import { FeatureToggleEvalFn } from '@assethub/shared/utils';
import { DialogService } from 'primeng/dynamicdialog';
import { LanguageService, LicensingService, TreeService } from '../../services';
import { IconComponent } from '../icon/icon.component';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { SwitchTreeComponent } from '../modals';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';

@Component({
  selector: 'app-menu-item-asset-move',
  templateUrl: '../menu/menu-item/menu-item.component.html',
  styleUrls: ['../menu/menu-item/menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent],
})
export class MenuItemAssetMoveComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
    private dialogService: DialogService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('shortcut', 'assetDetails.menu.switchTree');
  }

  static override isViableOption(
    asset: GetAssetResponse | AssetItem | undefined,
    opt: {
      featureToggleActive: FeatureToggleEvalFn;
    },
  ): boolean {
    if (BasicMenuItemAssetComponent.isManagedExternally(asset)) {
      return false;
    }
    if (
      BasicMenuItemAssetComponent.isRoot(asset) &&
      opt.featureToggleActive('MOVE_TREE_DISABLED')
    ) {
      return false;
    }
    return BasicMenuItemAssetComponent.isWritable(asset);
  }

  protected override getReason(opt: { featureToggleActive: FeatureToggleEvalFn }): string {
    if (BasicMenuItemAssetComponent.isManagedExternally(this.asset)) {
      return 'managedExternally';
    }
    if (
      BasicMenuItemAssetComponent.isRoot(this.asset) &&
      opt.featureToggleActive('MOVE_TREE_DISABLED')
    ) {
      return 'moveTreeNotAllowed';
    }
    if (!BasicMenuItemAssetComponent.isWritable(this.asset)) {
      return 'moveRequiresWrite';
    }
    return '';
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    this.dialogService.open(SwitchTreeComponent, {
      header: this.languageService.instant('switchAssetTree.dialog.title'),
      data: {
        assetId: this.asset.uuid,
      },
      dismissableMask: true,
      contentStyle: { flex: '1', display: 'flex' },
      style: {
        'min-height': '372px',
        'max-height': '600px',
        'min-width': '700px',
      },
    });
  }
}
