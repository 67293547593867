import { InjectionToken } from '@angular/core';
import { DashboardWidgets } from '@assethub/dashboard/components';
import { AuthConfig } from 'angular-oauth2-oidc';

export const ENVIRONMENT = new InjectionToken<Environment>('AssetHubEnvironment');

export interface Environment {
  dynamicSettings: boolean;
  production: boolean;
  title: string;
  debug: string;

  canonicalBaseUrl?: string;
  apiUrl: string;
  apiUrlEcs?: string;
  imageProxyUrl?: string;
  swsApiKey: string;
  iccUrl: string | null;
  icc2Url: string | null;
  iccWebsocketUrl: string | null;
  liveConnectUrl: string | null;

  sm365Url?: string | null;
  sm365DocumentProxyUrl?: string | null;
  sm365DocumentProxyApiKey?: string;
  sm365ApiKey?: string;

  integrationTestCredentials?: {
    assetHubClientId: string;
    assetHubClientSecret: string;
    assetHubTestUser: string;
    assetHubTestUserPassword: string;
  };

  oidc: AuthConfig;
  oidcPassChange: string;
  oidcProfile: string;

  configBackendUrl: string | null;
  configBackendApiKey?: string;

  integrationLayerProxyUrl: string | null;
  integrationLayerApiKey?: string;

  dtagBackendUrl?: string;

  hereWeGoApiKey: string;

  websocketUrl: string;

  ignoreConsentFailure?: boolean;
  digitalServiceCatalogUrl: {
    basePath: string;
    serviceIds: {
      basic: string;
      liveConnect: string;
      serviceModule: string;
    };
  };

  groupManagement?: {
    baseUrl: string;
    apiUrl: string;
  };

  sickUrl: string;
  additionalLanguages: string[];
  enableCustomerProductQuery: boolean;
  enableCreateTreeByUser: boolean;
  productFinderInterface: 'suggest' | 'search';
  monitoringBoxServiceUrl: string;
  artifactRepositoryUrl?: string;
  apppoolBackend?: string;
  apppoolFrontend?: string;

  visualRegressionTest?: {
    username: string;
    password: string;
  };

  defaultDashboard?: DashboardWidgets;

  featureToggles: { [key: string]: boolean };
}
