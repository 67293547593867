import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { GetAssetResponse } from '../models/asset-details';
import { AssetService } from '../services/asset.service';

export const assetResolver: ResolveFn<GetAssetResponse> = (route: ActivatedRouteSnapshot) => {
  const uuid: string | undefined = route.params.uuid;

  if (!uuid) {
    throw new Error('Route does not contain uuid parameter');
  }

  return inject(AssetService).getAsset(uuid);
};
