<div class="new-group" [ngClass]="{ narrow: narrow }">
  <span class="p-float-label group-autocomplete">
    <p-autoComplete
      id="group-search-field"
      type="GroupSearchEntry"
      class="required"
      field="displayName"
      scrollHeight="400px"
      [(ngModel)]="suggestionInput"
      [autoHighlight]="true"
      [dropdown]="true"
      [dropdownIcon]="activeSearch ? 'pi pi-spinner pi-spin' : 'pi pi-search'"
      [minLength]="3"
      [suggestions]="groupSearchSuggestions"
      [emptyMessage]="'groupSearch.empty' | translate"
      [showEmptyMessage]="true"
      (completeMethod)="searchGroups($event)"
      (onSelect)="selectGroup($event)"
    >
      <ng-template let-group pTemplate="item">
        <div class="group-suggestion">
          <div>{{ group.displayName }}</div>
          <button
            pButton
            type="button"
            class="ui-button-outline p-button-icon-only group-button"
            icon="pi pi-group"
            (click)="
              $event.preventDefault(); $event.stopPropagation(); navigateToGroup(group.extGroupId)
            "
          ></button>
        </div>
      </ng-template>
    </p-autoComplete>
    <label for="group-search-field">{{ 'groupSearch.placeholder' | translate }}</label>
  </span>

  <p-dropdown class="permission-selector" [options]="permissionOptions" [(ngModel)]="permissions">
    <ng-template let-permission pTemplate="selectedItem">
      {{ permission.label | translate }}
    </ng-template>
    <ng-template let-permission pTemplate="item">
      {{ permission.label | translate }}
    </ng-template>
  </p-dropdown>

  <button
    pButton
    type="button"
    class="grant-permission"
    label="{{ 'assetPermissions.grant' | translate }}"
    (click)="onNewGroupPermission()"
  ></button>
</div>
