import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type AssetDetailsAccordionId = '' | 'images';

@Injectable({ providedIn: 'root' })
export class AssetDetailsAccordionService {
  private _openAccordion = new BehaviorSubject<AssetDetailsAccordionId>('');

  get openAccordion$(): Observable<AssetDetailsAccordionId> {
    return this._openAccordion.asObservable();
  }

  openAccordion(accordionId: AssetDetailsAccordionId): void {
    this._openAccordion.next(accordionId);
  }
}
