import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasicMenuComponent } from '../basic-menu.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { IconComponent } from '../../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent],
})
export class MenuItemComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() icon?: string;
  @Input() iconSrc = '';
  @Input() iconName?: string;
  @Input() reason = '';
  @HostBinding('class.disabled')
  @Input()
  disabled = false;
  @Output() selected = new EventEmitter();

  shape?: string | SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    @SkipSelf() @Optional() private parentMenu: BasicMenuComponent | null,
  ) {}

  ngOnInit(): void {
    this.updateIcon();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon || changes.iconName || changes.iconSrc) {
      this.updateIcon();
    }
  }

  @HostListener('click', [])
  onClick() {
    if (this.disabled) {
      return;
    }
    if (this.parentMenu) {
      this.parentMenu.hideAll();
    }
    this.selected.emit();
  }

  private updateIcon() {
    this.shape = this.icon ?? this.iconName;
    if (this.iconSrc) {
      // [innerHtml] filters out SVGs. To make it work it has to be sanitized explicitly
      this.shape = this.sanitizer.bypassSecurityTrustHtml(this.iconSrc);
    }
  }
}
