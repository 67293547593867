import { DownloadServiceFileComponent } from './download-service-file/download-service-file.component';
import { ListCasesComponent } from './list-cases/list-cases.component';
import { ListWorkOrderDocumentsComponent } from './list-work-order-documents/list-work-order-documents.component';
import { ListWorkOrdersComponent } from './list-work-orders/list-work-orders.component';

export const sm365Components = [
  DownloadServiceFileComponent,
  ListCasesComponent,
  ListWorkOrdersComponent,
  ListWorkOrderDocumentsComponent,
];
