import { NgClass, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { ENVIRONMENT, GetAssetResponse, QueryFilter } from '@assethub/shared/models';
import {
  AppPoolService,
  AppSearchResult,
  SensorApp,
} from '@assethub/shared/services/apppool.service';
import { Logger, SDSColors, validateIsVendorSick } from '@assethub/shared/utils';
import {
  IccDeviceInfo,
  IccRegistrationState,
  LiveConnectProfile,
  PROFILE,
} from '@liveconnect/shared/models';
import {
  CapabilitiesService,
  DeviceMonitorService,
  IccService,
} from '@liveconnect/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import {
  EMPTY,
  Subject,
  catchError,
  exhaustMap,
  filter,
  finalize,
  first,
  interval,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { CalloutComponent } from '../callout/callout.component';
import { IconComponent } from '../icon/icon.component';
import { ProgressStatusComponent } from '../status-line/progress-status.component';
import {
  DeviceStateError,
  RemoteAppManagerService,
} from './../../services/remote-app-manager.service';

type Device = Required<Pick<GetAssetResponse, 'details' | 'uuid' | 'permissions'>>;

type AppModelInternal = SensorApp & {
  protected: boolean;
};

export interface AppResultInternal extends AppSearchResult {
  apps: AppModelInternal[];
}

class RemoteServiceIssues {
  deviceUnpaired?: true;
  deviceOffline?: true;
  remoteAppUnavailable?: true;
  serviceVersionInsufficient?: true;
  insufficientPermissions?: true;
  constructor(params: Partial<RemoteServiceIssues>) {
    Object.assign(this, params);
  }
}

// Apps that cannot be uninstalled from the device
const PROTECTED_APP_IDS = [
  // Liveconnect App
  // DEV
  '0a76d60c-2fc5-4ed1-9a34-ed7382625bfe',
  // PROD
  'dec76910-da81-4072-9639-c7c93e9dd474',
];
const DEFAULT_APPS: AppResultInternal = {
  apps: [],
  totalAppCount: 0,
};

@Component({
  selector: 'app-sensor-apps',
  templateUrl: './sensor-apps.component.html',
  styleUrls: ['./sensor-apps.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CalloutComponent,
    ProgressStatusComponent,
    TableModule,
    NgClass,
    SharedModule,
    IconComponent,
    ButtonModule,
    TranslateModule,
  ],
})
export class SensorAppsComponent implements OnChanges, OnDestroy {
  @Input({ required: true }) device: Device;
  @Input() narrow = false;
  @ViewChild('appTable') appTable: Table;
  @ViewChild('progressStatusLine') progressStatusLine: ProgressStatusComponent;

  appResults: AppResultInternal = DEFAULT_APPS;
  loading = false;
  errorTranslationKey: string | undefined;
  isSupportedDevice = false;
  disabledEllipsisIds: { [keyof: number /* Row index */]: boolean } = {};
  defaultRowCount = 10;

  remoteServiceIssues: RemoteServiceIssues | undefined;
  remoteAppServiceLocation: string | undefined;
  readonly remoteAppMajorVersion = 1;
  readonly remoteAppMinorVersion = 2;

  readonly appPoolFrontendUrl = inject(ENVIRONMENT).apppoolFrontend;

  readonly colorSuccess = SDSColors.success;
  readonly remoteOperationDisplayTimeout = 5000;
  readonly errMsgDisplayTimeout = 5000;

  private readonly logger = new Logger(this.constructor.name);
  private readonly completeRunningTasks$: Subject<void> = new Subject();

  constructor(
    private appPoolService: AppPoolService,
    private iccClient: IccService,
    private deviceMonitor: DeviceMonitorService,
    private capabilitiesService: CapabilitiesService,
    private remoteAppsClient: RemoteAppManagerService,
  ) {}

  ngOnDestroy(): void {
    this.completeRunningTasks$.next();
    this.completeRunningTasks$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const device: Device | undefined = changes['device']?.currentValue;
    if (device) {
      this.handleDeviceChanged();
    }
  }

  toggleRowEllipsis(index: number) {
    this.disabledEllipsisIds[index] = !this.disabledEllipsisIds[index];
  }

  loadApps(pagingEvent?: TableLazyLoadEvent) {
    if (this.appTable && !pagingEvent) {
      // Reset the pager
      this.appTable.first = 0;
    }

    const firstPage = pagingEvent?.first || 0;
    const rows = pagingEvent?.rows || this.defaultRowCount;
    let offset = 0;
    if (pagingEvent && firstPage >= rows) {
      // AppPool has different handling for offset (page wise instead skipping rows)
      offset = Math.trunc(firstPage / rows);
    }
    const pagingOpts: Required<Pick<QueryFilter, 'offset' | 'size'>> = {
      offset,
      size: rows,
    };

    this.loading = true;
    const deviceId = this.device.uuid;
    const partNumber = this.device.details.partNumber;

    this.appPoolService
      .findApps(deviceId, partNumber, pagingOpts)
      .pipe(
        takeUntil(this.completeRunningTasks$),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: appResults => {
          this.appResults = {
            totalAppCount: appResults.totalAppCount,
            apps: appResults.apps.map(app => ({
              ...app,
              protected: PROTECTED_APP_IDS.includes(app.uuid),
            })),
          };
          this.repositionInstalledApps();
        },
        error: error => {
          this.logger.error('Error loading SensorApps.', error);
          this.displayError('sensor-apps.errors.load-failed');
          this.appResults = DEFAULT_APPS;
          this.disabledEllipsisIds = {};
        },
      });
  }

  installApp(app: SensorApp) {
    if (!app.latestPublishedVersion) {
      return;
    }

    this.loading = true;
    const latestPublishedVersion = app.latestPublishedVersion;
    this.progressStatusLine.reset();
    this.progressStatusLine.next({
      state: 'pending',
      messageTranslateKey: 'sensor-apps.app-install.prepare',
    });

    this.appPoolService
      .getAppDownloadUrl(app.uuid, latestPublishedVersion.uuid)
      .pipe(
        switchMap(downloadUrl =>
          this.remoteAppsClient.installApp(this.remoteAppServiceLocation!, downloadUrl),
        ),
        tap(() => this.progressStatusLine.updateState('success')),
        tap(() =>
          this.progressStatusLine.next({
            state: 'pending',
            messageTranslateKey: 'sensor-apps.app-install.wait-for-completion',
          }),
        ),
        switchMap(() =>
          interval(3000).pipe(
            // service location will be set to undefined during reboot. Skip additional status calls until device is up again
            filter(() => this.remoteAppServiceLocation !== undefined),
            exhaustMap(() =>
              this.remoteAppsClient.getServiceState(this.remoteAppServiceLocation!).pipe(
                tap(state => {
                  if (state.executing && typeof state.progress === 'number') {
                    // Origin format 0.XX. Cut off from second decimal and convert it to full percentage number.
                    const progress = Math.floor(state.progress * 10) * 10;
                    this.progressStatusLine.updateState('pending', undefined, progress);
                  }
                }),
                catchError(err => {
                  // Device reboots during App installation.
                  const isExpectedError =
                    err instanceof HttpErrorResponse &&
                    (err.status === 502 ||
                      err.status === 504 ||
                      err.status === 0); /* Device not reachable */
                  if (isExpectedError) {
                    this.logger.debug('Device reboots right now');
                    return EMPTY;
                  }

                  throw err;
                }),
              ),
            ),
            filter(state => !state.executing),
            first(),
          ),
        ),
        takeUntil(this.completeRunningTasks$),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: () => {
          this.progressStatusLine.updateState('success', 'sensor-apps.app-install.completed');
          this.progressStatusLine.complete();
          app.installedVersion = latestPublishedVersion;
          this.repositionInstalledApps();
        },
        error: error => {
          if (error instanceof DeviceStateError) {
            if (error.deviceBusyError) {
              this.progressStatusLine.updateState('error', 'sensor-apps.device-errors.device-busy');
              return;
            }
            if (error.firmwareIncompatible) {
              this.progressStatusLine.updateState(
                'error',
                'sensor-apps.device-errors.firmware-incompatible',
              );
              return;
            }
          }
          this.progressStatusLine.updateState('error', 'sensor-apps.device-errors.common');
        },
      });
  }

  removeApp(app: SensorApp) {
    if (!app.installedVersion) {
      return;
    }

    this.loading = true;
    const installedVersion = app.installedVersion;
    this.progressStatusLine.reset();
    this.progressStatusLine.next({
      state: 'pending',
      messageTranslateKey: 'sensor-apps.app-uninstall.wait-for-completion',
    });

    this.appPoolService
      .getAppNamesIncluded(installedVersion.uuid)
      .pipe(
        switchMap(appsToRemove =>
          this.remoteAppsClient.removeApps(this.remoteAppServiceLocation!, appsToRemove).pipe(
            catchError(err => {
              // Device reboots during App uninstallation.
              const isExpectedError =
                err instanceof HttpErrorResponse &&
                (err.status === 502 ||
                  err.status === 504 ||
                  err.status === 0); /* Device not reachable */
              if (isExpectedError) {
                this.logger.debug('Device reboots right now');
                return of(undefined);
              }

              throw err;
            }),
          ),
        ),
        takeUntil(this.completeRunningTasks$),
        finalize(() => (this.loading = false)),
      )
      .subscribe({
        next: () => {
          this.progressStatusLine.updateState('success', 'sensor-apps.app-uninstall.completed');
          this.progressStatusLine.complete();
          app.installedVersion = undefined;
          this.repositionInstalledApps();
        },
        error: error => {
          if (error instanceof DeviceStateError && error.firmwareIncompatible) {
            this.progressStatusLine.updateState(
              'error',
              'sensor-apps.device-errors.firmware-incompatible',
            );
            return;
          }
          this.progressStatusLine.updateState('error', 'sensor-apps.device-errors.common');
        },
      });
  }

  private handleDeviceChanged() {
    this.resetView();
    this.isSupportedDevice =
      validateIsVendorSick(this.device.details.vendor) && !!this.device.details.partNumber;
    if (!this.isSupportedDevice) {
      return;
    }
    this.updateRemoteAppCapabilities();
    this.loadApps();
  }

  private repositionInstalledApps() {
    // Show Apps installed on current device prior others
    this.appResults.apps.sort((a, b) => {
      if (!a.installedVersion?.uuid && b.installedVersion?.uuid) {
        return 1;
      } else if (a.installedVersion?.uuid && !b.installedVersion?.uuid) {
        return -1;
      }
      return 0;
    });
  }

  private updateRemoteAppCapabilities() {
    const updateDeviceIssues = (issue: keyof RemoteServiceIssues, isFailed: boolean) => {
      if (isFailed) {
        this.remoteAppServiceLocation = undefined;
        this.remoteServiceIssues = { [issue]: true };
      } else {
        this.remoteServiceIssues = undefined;
      }
    };

    const hasWritePermissions = this.device.permissions.includes('w');
    updateDeviceIssues('insufficientPermissions', !hasWritePermissions);
    if (!hasWritePermissions) {
      return;
    }

    const deviceId = this.device.uuid;
    const isPairedCheck = this.iccClient.getDeviceInfo(deviceId).pipe(
      filter((deviceInfo: IccDeviceInfo) => {
        this.logger.debug('Device Info: ', deviceInfo);
        if (
          deviceInfo.deviceUuid === deviceId &&
          deviceInfo.state === IccRegistrationState.UNKNOWN
        ) {
          // quietly wait for the actual state - or remain silent if ICC not supported in this environment
          this.logger.debug('Device pairing state is unknown.');
          return false;
        }
        const isPaired =
          deviceInfo.deviceUuid === deviceId &&
          (deviceInfo.state === IccRegistrationState.PEERED ||
            deviceInfo.state === IccRegistrationState.REGISTERED);
        updateDeviceIssues('deviceUnpaired', !isPaired);
        return isPaired;
      }),
    );

    const isOnlineCheck = this.deviceMonitor.isConnected(deviceId).pipe(
      filter((isOnline: boolean) => {
        this.logger.debug('Device connection state: ', isOnline);
        this.logger.debug('Device uuid: ', deviceId);
        updateDeviceIssues('deviceOffline', !isOnline);
        return isOnline;
      }),
    );

    const appManagerRemoteAppCheck = this.capabilitiesService.getCapabilities(deviceId).pipe(
      tap((capabilities: LiveConnectProfile[]) =>
        this.logger.debug('Device capabilities: ', capabilities),
      ),
      map((capabilities: LiveConnectProfile[]) =>
        capabilities.find(capa => capa.id === PROFILE.APP_MANAGER_REMOTE),
      ),
      filter((remoteAppCapability: LiveConnectProfile | undefined) => {
        updateDeviceIssues('remoteAppUnavailable', !remoteAppCapability);
        return !!remoteAppCapability;
      }),
      filter((remoteAppCapability: LiveConnectProfile) => {
        const isRequiredVersion = this.isRemoteAppCompatible(remoteAppCapability.version);
        updateDeviceIssues('serviceVersionInsufficient', !isRequiredVersion);
        return isRequiredVersion;
      }),
    );

    isPairedCheck
      .pipe(
        switchMap(() => isOnlineCheck),
        switchMap(() => appManagerRemoteAppCheck),
        map((remoteAppCapability: LiveConnectProfile) => {
          if (!remoteAppCapability.serviceLocation) {
            throw new Error('No service location found for App Manager Remote');
          }
          return remoteAppCapability.serviceLocation;
        }),
        takeUntil(this.completeRunningTasks$),
        catchError(error => {
          this.logger.error('Error during remote app installation capability check: ', error);
          this.displayError('sensor-apps.errors.capability-check-failed');
          this.remoteAppServiceLocation = undefined;
          return EMPTY;
        }),
      )
      .subscribe({
        next: (serviceLocation: string) => {
          this.logger.debug('All capability checks for remote app installation passed.');
          this.remoteAppServiceLocation = serviceLocation;
        },
      });
  }

  private isRemoteAppCompatible(serviceVersion: string): boolean {
    const versionParts = serviceVersion.split('.');
    const majorVersion = Number.parseInt(versionParts[0], 10);
    const minorVersion = Number.parseInt(versionParts[1], 10);
    // At least 1.2 and < 2
    const isMajorVersionCompatible = majorVersion === this.remoteAppMajorVersion;
    const isMinorVersionCompatible = minorVersion >= this.remoteAppMinorVersion;
    if (!isMajorVersionCompatible || !isMinorVersionCompatible) {
      this.logger.warn(
        'App Manager Remote Service version min required: %d.%d and < 2.0. App version found on device was %d.%d',
        this.remoteAppMajorVersion,
        this.remoteAppMinorVersion,
        majorVersion,
        minorVersion,
      );
      return false;
    }

    return true;
  }

  private displayError(translationKey: string) {
    this.errorTranslationKey = translationKey;
    setTimeout(() => {
      this.errorTranslationKey = undefined;
    }, this.errMsgDisplayTimeout);
  }

  private resetView() {
    this.completeRunningTasks$.next();
    this.progressStatusLine?.reset();
    this.errorTranslationKey = undefined;
    this.loading = false;
    this.appResults = DEFAULT_APPS;
    this.disabledEllipsisIds = {};
    this.remoteServiceIssues = undefined;
    this.remoteAppServiceLocation = undefined;
  }
}
