<div class="no-asset-selected">
  <div class="header">
    <app-icon [shape]="icon"></app-icon>
    <h3>
      {{ serviceName }}
    </h3>
  </div>
  <h6>
    {{ callToSelect }}
  </h6>
  <ng-content></ng-content>
</div>
