import { SDSColors } from '@assethub/shared/utils';

export type FirmwareState = 'CURRENT' | 'UPDATE_AVAILABLE' | 'UNKNOWN';

export const AVAILABLE_FIRMWARE_STATES: FirmwareState[] = [
  'CURRENT',
  'UPDATE_AVAILABLE',
  'UNKNOWN',
];

export const FIRMWARE_STATE_ICON: Record<FirmwareState, { shape: string; color: string }> = {
  CURRENT: {
    shape: 'check_circle',
    color: SDSColors.success,
  },
  UPDATE_AVAILABLE: {
    shape: 'warning',
    color: SDSColors.warning,
  },
  UNKNOWN: {
    shape: 'help',
    color: SDSColors.secondary,
  },
};
