<app-menu direction="dl" #menu>
  <app-menu-item-asset-edit [asset]="asset()"></app-menu-item-asset-edit>
  <app-menu-item-asset-create
    *ngIf="optionVisibilities.showCreateAssetOption"
    [asset]="asset()"
  ></app-menu-item-asset-create>
  <app-menu-item-asset-delete
    *ngIf="optionVisibilities.showDeleteAssetOption"
    [asset]="asset()"
  ></app-menu-item-asset-delete>
  <app-menu-item-asset-clone
    *ngIf="optionVisibilities.showCloneAssetOption"
    [asset]="asset()"
  ></app-menu-item-asset-clone>
  <app-menu-item-asset-move
    *ngIf="optionVisibilities.showMoveAssetOption"
    [asset]="asset()"
  ></app-menu-item-asset-move>
  <app-menu-item-asset-export-csv [asset]="asset()"></app-menu-item-asset-export-csv>
  <app-menu-item-asset-export-aas
    *ngIf="FEATURE_TOGGLE_EXPORT_AASX_FILE"
    [asset]="asset()"
  ></app-menu-item-asset-export-aas>
  <app-menu-item
    *ngIf="optionVisibilities.showDownloadAssetAdminShellOption"
    [label]="'assetDetails.menu.download-aasx-file' | translate"
    icon="pi pi-cloud-download"
    (selected)="downloadAssetAdminShell()"
  ></app-menu-item>
  <ng-content></ng-content>
  <div class="menu-group">
    <hr />
    <app-menu-item-asset-end-sharing
      *ngIf="optionVisibilities.showLeaveSharedTreeOption"
      [asset]="asset()"
    ></app-menu-item-asset-end-sharing>
  </div>
</app-menu>
