<app-menu
  #menu
  direction="rm"
  class="ui-button-bare-neutral"
  [depth]="10000"
  [icon]="icon"
  [iconColor]="iconColor"
  [iconSize]="iconSize"
  [label]="label"
  [hideOnAnyUserInteraction]="true"
  [backgroundColor]="backgroundColor"
  [exclusive]="false"
  (showMenu)="onShowMenu()"
  ><div [ngStyle]="_tooltipStyle"><ng-content></ng-content></div
></app-menu>
