import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
  OnChanges,
  ContentChild,
  TemplateRef,
  forwardRef,
  OnInit,
} from '@angular/core';
import { BasicMenuComponent } from '../basic-menu.component';
import { MenuService } from '../../../services/menu.service';
import { TooltipService } from '../../../services/tooltip.service';
import { IconComponent } from '../../icon/icon.component';
import { NgIf, NgStyle, NgClass } from '@angular/common';

const TRIANGLE_GAP = 7;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [{ provide: BasicMenuComponent, useExisting: forwardRef(() => MenuComponent) }],
  standalone: true,
  imports: [NgIf, IconComponent, NgStyle, NgClass],
})
export class MenuComponent extends BasicMenuComponent implements OnDestroy, OnChanges, OnInit {
  // First letter defines on which side of the menu button the content appears
  // (u)p = above button, (d)own = below button, (l)eft, (r)ight
  // If there is not enough space on the desired side of the button, placement
  // will switch to the other side.
  //
  // Second letter defines the alignment of the content box.
  // For above and below, valid options are (l)eft, (m)iddle, (r)ight
  // For left and right, valid options are (d)own, (m)iddle, (u)p
  // If there is not enough space to fit the box, placement will gradually shift
  // into the other direction.
  @Input() direction = 'dm';
  @Input() icon = 'more_vert';
  @Input() iconColor = '';
  @Input() iconSize: string | number = 24;
  @Input() label = '';
  @Input() title = '';
  @Input() showTriangle = true;
  // When an icon is present, the content box is positioned relative to that and the label is ignored.
  // This option can be used to change that behavior. If no icon is present, positioning will always
  // be relative to the component.
  @Input() anchor: 'icon' | 'component' = 'icon';
  @Input() override openOnHover: boolean;
  // Change the distance between the edge of the content box and middle of the menu button.
  // Ignored if the alignment is set to "middle".
  @Input() offset: string | number = 11;
  // Note: to change the distance between menu button and content box use the "gap" option
  // provided by the base class.
  @Input() exclusive = true; // whether to close all other menus on opening

  @ContentChild('customLabel', { static: false }) customLabel: TemplateRef<any>;

  menuFrameClass: string[] = [];
  menuContentStyle: Record<string, string | number> = {};

  @ViewChild('menuIcon')
  private menuIcon: ElementRef<HTMLDivElement>;

  @ViewChild('menuFrame')
  protected menuFrame: ElementRef<HTMLDivElement>;

  constructor(
    element: ElementRef<HTMLElement>,
    menuService: MenuService,
    tooltipService: TooltipService,
  ) {
    super(null, element, menuService, tooltipService);
  }

  override show(): void {
    if (this.exclusive && !this.disabled && !this.displayMenu) {
      // no content shown at the moment, so hide all other open menus first
      this.menuService.hideAll();
    }
    super.show();
  }

  protected override isOutsideComponent(x: number, y: number): boolean {
    const anchor = this.menuIcon?.nativeElement || this.element.nativeElement;
    const rect = anchor.getBoundingClientRect();
    return x < rect.left || x > rect.right || y < rect.top || y > rect.bottom;
  }

  protected override positionMenuContent(): void {
    // position menu relative to button
    let gap = typeof this.gap === 'string' ? parseInt(this.gap, 10) : this.gap;
    gap += this.showTriangle ? TRIANGLE_GAP : 0;
    const dir = this.direction.split('');
    dir[0] = this.handlePrimaryDirection(dir[0], gap);
    if (dir[0] === 'u' || dir[0] === 'd') {
      dir[1] = this.handleSecondaryDirectionHorizontal(dir[1]);
    } else {
      dir[1] = this.handleSecondaryDirectionVertical(dir[1]);
    }
    const menuFrame = this.menuFrame.nativeElement;
    menuFrame.style.setProperty('--menu-triangle-color', this.backgroundColor);
    menuFrame.style.setProperty('--menu-triangle-offset', `${this.offset}px`);
    if (this.showTriangle) {
      this.menuFrameClass = ['triangle', dir.join('')];
      this.menuContentStyle = {};
      switch (dir[0]) {
        case 'u':
          this.menuContentStyle['padding-bottom.px'] = gap;
          break;
        case 'd':
          this.menuContentStyle['padding-top.px'] = gap;
          break;
        case 'l':
          this.menuContentStyle['padding-right.px'] = gap;
          break;
        case 'r':
          this.menuContentStyle['padding-left.px'] = gap;
          break;
      }
    }
  }

  // Try to fit menu-canvas into the desired primary direction. Returns chosen direction.
  private handlePrimaryDirection(dir: string, gap: number): string {
    const menuContent = this.menuContent.nativeElement;
    const menuFrame = this.menuFrame.nativeElement;
    const anchor =
      (this.anchor === 'icon' ? this.menuIcon?.nativeElement : undefined) ||
      this.element.nativeElement;
    const buttonRect = anchor.getBoundingClientRect();
    const aboveStart = buttonRect.top - menuFrame.clientHeight - gap;
    const belowStart = buttonRect.bottom;
    const belowEnd = belowStart + menuFrame.clientHeight + gap;
    const leftStart = buttonRect.left - menuFrame.clientWidth - gap;
    const rightStart = buttonRect.right;
    const rightEnd = rightStart + menuFrame.clientWidth + gap;
    switch (dir) {
      case 'u':
        // put menu above button
        if (aboveStart >= 0) {
          menuContent.style.top = `${aboveStart}px`;
          return 'u';
        } else {
          // if menu does not fit above button, but it below
          menuContent.style.top = `${belowStart}px`;
          return 'd';
        }
      case 'd':
        // put menu below button
        if (belowEnd < window.innerHeight) {
          menuContent.style.top = `${belowStart}px`;
          return 'd';
        } else {
          // if menu does not fit below button, put it above
          menuContent.style.top = `${aboveStart}px`;
          return 'u';
        }
      case 'l':
        // put menu left of button
        if (leftStart >= 0) {
          menuContent.style.left = `${leftStart}px`;
          return 'l';
        } else {
          // does not fit to the left so put it to the right
          menuContent.style.left = `${rightStart}px`;
          return 'r';
        }
      case 'r':
        // put menu right of button
        if (rightEnd < window.innerWidth) {
          menuContent.style.left = `${rightStart}px`;
          return 'r';
        } else {
          // does not fit to the right so put it to the left
          menuContent.style.left = `${leftStart}px`;
          return 'l';
        }
      default:
        throw new Error('bad direction ' + this.direction);
    }
  }

  private handleSecondaryDirectionHorizontal(dir: string): string {
    const menuContent = this.menuContent.nativeElement;
    const anchor = this.menuIcon?.nativeElement || this.element.nativeElement;
    const buttonRect = anchor.getBoundingClientRect();
    const offset = typeof this.offset === 'string' ? parseInt(this.offset, 10) : this.offset;
    const leftStart = buttonRect.left + buttonRect.width / 2 + 6 + offset - menuContent.clientWidth;
    const middleStart = buttonRect.left + (buttonRect.width - menuContent.clientWidth) / 2;
    const middleEnd = middleStart + menuContent.clientWidth;
    const rightStart = buttonRect.left + buttonRect.width / 2 - 6 - offset;
    const rightEnd = rightStart + menuContent.clientWidth;
    switch (dir) {
      case 'l':
        // put menu left of button
        if (leftStart >= 0) {
          menuContent.style.left = `${leftStart}px`;
          return 'l';
        } else if (middleStart >= 0) {
          // no space -> but middle position would work
          menuContent.style.left = `${middleStart}px`;
          return 'm';
        } else {
          // fall-back to right position
          menuContent.style.left = `${rightStart}px`;
          return 'r';
        }
      case 'm':
        // center menu relative to button
        if (middleStart >= 0) {
          if (middleEnd < window.innerWidth) {
            menuContent.style.left = `${middleStart}px`;
            return 'm';
          } else {
            // not enough space to the right -> fall back to left
            menuContent.style.left = `${leftStart}px`;
            return 'l';
          }
        } else {
          // not enough space to the left -> fall back to right
          menuContent.style.left = `${rightStart}px`;
          return 'r';
        }
      case 'r':
        // put menu right of button
        if (rightEnd < window.innerWidth) {
          menuContent.style.left = `${rightStart}px`;
          return 'r';
        } else if (middleEnd < window.innerWidth) {
          // not enough space, but middle position would work
          menuContent.style.left = `${middleStart}px`;
          return 'm';
        } else {
          // fall-back to left position
          menuContent.style.left = `${leftStart}px`;
          return 'l';
        }
      default:
        throw new Error('bad direction ' + this.direction);
    }
  }

  private handleSecondaryDirectionVertical(dir: string): string {
    const menubox = this.menuContent.nativeElement;
    const anchor = this.menuIcon?.nativeElement || this.element.nativeElement;
    const buttonRect = anchor.getBoundingClientRect();
    const offset = typeof this.offset === 'string' ? parseInt(this.offset, 10) : this.offset;
    const upStart = buttonRect.top + buttonRect.height / 2 + 6 + offset - menubox.clientHeight;
    const middleStart = buttonRect.top + (buttonRect.height - menubox.clientHeight) / 2;
    const middleEnd = middleStart + menubox.clientHeight;
    const downStart = buttonRect.top + buttonRect.height / 2 - 6 - offset;
    const downEnd = downStart + menubox.clientHeight;
    switch (dir) {
      case 'u':
        // place menu stretching upwards
        if (upStart >= 0) {
          menubox.style.top = `${upStart}px`;
          return 'u';
        } else if (middleStart >= 0) {
          // no space -> but middle position would work
          menubox.style.top = `${middleStart}px`;
          return 'm';
        } else {
          // fall-back to bottom position
          menubox.style.top = `${downStart}px`;
          return 'd';
        }
      case 'm':
        // center menu relative to button
        if (middleStart >= 0) {
          if (middleEnd < window.innerHeight) {
            menubox.style.top = `${middleStart}px`;
            return 'm';
          } else {
            // not enough space downwards -> fall back to pointing up
            menubox.style.top = `${upStart}px`;
            return 'u';
          }
        } else {
          // not enough space above -> fall back to pointing down
          menubox.style.top = `${downStart}px`;
          return 'd';
        }
      case 'd':
        // place menu stretching downwards
        if (downEnd < window.innerHeight) {
          menubox.style.top = `${downStart}px`;
          return 'd';
        } else if (middleEnd < window.innerHeight) {
          // not enough space, but middle position would work
          menubox.style.top = `${middleStart}px`;
          return 'm';
        } else {
          // fall-back to pointing up
          menubox.style.top = `${upStart}px`;
          return 'u';
        }
      default:
        throw new Error('bad direction ' + this.direction);
    }
  }
}
