<app-table-base
  #table
  [list]="list"
  [columns]="columns"
  [autoWidth]="false"
  tableName="device-children"
  noDataMsg="device-children.noElements"
  errorMsg="device-children.load-error"
  nofilteredDataMsg="device-children.all-entries-filtered-out"
  ><ng-template #cellTemplate let-column let-row="row">
    @switch (column.field) {
      @case ('productName') {
        {{ row.productName }}
      }
      @case ('customName') {
        <app-asset-chip [asset]="row"></app-asset-chip>
      }
      @case ('firmwareVersion') {
        {{ row.firmwareVersion }}
      }
      @case ('firmwareState') {
        @if (featureToggleActive('SOFTWARE_STATE')) {
          <app-firmware-state-icon [firmwareState]="row.firmwareState"></app-firmware-state-icon>
        }
      }
    }</ng-template
></app-table-base>
