import { NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENVIRONMENT } from '@assethub/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { NavigationSpinnerComponent } from '../navigation-spinner/navigation-spinner.component';
import { NoAssetSelectedComponent } from '../no-asset-selected/no-asset-selected.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [
    NavigationSpinnerComponent,
    NgIf,
    IconComponent,
    NoAssetSelectedComponent,
    TranslateModule,
  ],
})
export class ErrorComponent implements OnInit {
  public reason = '';
  public subscription = '';
  public serviceCatalogUrl = '';

  private digitalServiceCatalogUrl = inject(ENVIRONMENT).digitalServiceCatalogUrl;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (data.subscription) {
        this.reason = 'subscription';
        this.subscription = data.subscription;
        this.serviceCatalogUrl =
          this.digitalServiceCatalogUrl.basePath +
          '/services/' +
          this.digitalServiceCatalogUrl.serviceIds[this.subscription];
      } else {
        this.reason = data.reason;
        this.serviceCatalogUrl = this.digitalServiceCatalogUrl.basePath;
      }
    });
  }
}
