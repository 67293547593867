<div
  class="searchbox"
  [attr.tabindex]="expanded ? -1 : 0"
  (click)="expand()"
  (keypress)="onKeyboard($event)"
>
  <input
    type="text"
    placeholder="{{ 'common.search' | translate }}"
    [attr.tabindex]="expanded ? 0 : -1"
    [(ngModel)]="value"
    (ngModelChange)="onInput()"
    (keydown.enter)="onEnterKeyDown()"
    (keyup.enter)="onEnterKeyUp()"
    (blur)="onBlur()"
  />
  <app-icon shape="pi-search" size="large" class="icon" (click)="iconClicked()"></app-icon>
  <button
    pButton
    type="button"
    title="Clear input"
    class="ui-button-bare-neutral clear"
    icon="pi pi-small pi-times"
    [ngClass]="clearButtonClass"
    [ngStyle]="{ 'transition-duration.ms': buttonFadeTime }"
    (click)="onClearButton()"
  ></button>
</div>
<button
  pButton
  type="button"
  title="Close search"
  class="ui-button-bare close"
  icon="pi pi-times"
  [ngClass]="closeButtonClass"
  [ngStyle]="{ 'transition-duration.ms': buttonFadeTime }"
  (click)="collapse()"
></button>
