export interface DocumentUpload {
  fileName: string;
  size: number;
}

export interface PersistedDocument {
  lastUpdated: Date;
  fileName: string;
  size: number;
  uuid: string;
}

export interface PagedDocumentResponse {
  count: number;
  documents: PersistedDocument[];
}

export interface DocumentUploadMeta {
  documentUpload: {
    fileName: string;
    size: number;
    duplicate: boolean;
    uuid: string;
  };
  presignedUrl: {
    url: string;
    fields: object;
  };
}

export interface DocumentUploadFeedback {
  remaining: number;
  doc: PersistedDocument | null;
}
