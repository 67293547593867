import { AssetImages } from './asset-images';
import { Link } from '@assethub/asset-management/models/link';
import { FirmwareState } from './firmware-state';

export enum AssetTypeMapping {
  LINE = 1,
  DEVICE = 2,
  BUILDING = 3,
  LOCATION = 4,
  COMPANY = 5,
  MACHINE_SYSTEM = 6,
  SHIP = 7,
  FACTORY = 8,
  SOFTWARE = 11,
}

export const assetTypeMappingAsString: Readonly<Record<string, string>> = Object.fromEntries(
  Object.entries(AssetTypeMapping).map(x => [x[0], x[1].toString()]),
);

// List of all asset-types in lower letters. Index of each element is its corresponding numeric ID. Example: a[3]="building"
export const assetTypeMappingToString = Object.entries(AssetTypeMapping).reduce<string[]>(
  (a, x) => (typeof x[1] === 'number' ? (a[x[1]] = x[0].toLowerCase()) : 0, a),
  [],
);

export const interchangeableTypeMappings = [
  AssetTypeMapping.COMPANY,
  AssetTypeMapping.LOCATION,
  AssetTypeMapping.BUILDING,
  AssetTypeMapping.LINE,
  AssetTypeMapping.FACTORY,
];

export interface DeviceDetails {
  serialNumber: string;
  productName: string;
  vendor: string;
  partNumber: string;
  owner: string;
  created: number;
  productPictureUrl?: string;
  firmwareVersion?: string;
  firmwareState?: FirmwareState;
}

export interface GetAssetResponse {
  uuid: string;
  typeId: number;
  customName: string;
  deploymentDate?: number | null;
  rootUuid: string | null;
  rootName?: string;
  parentUuid: string | null;
  treeUuid?: string;
  description: string;
  ownerEmail?: string;
  permissions?: string;
  breadcrumb?: AssetBreadcrumb[];
  images?: AssetImages[];
  profilePicture?: AssetImages;
  details?: DeviceDetails;
  shipDetails?: ShipDetails;
  links?: Link[];
  extId?: string;
  managed?: boolean;
  inventoryDetails?: { updatedTime?: number; synced: boolean };
}

export function assetName(asset?: GetAssetResponse): string {
  return asset?.customName || asset?.details?.productName || '';
}

export interface ShipDetails {
  imo: string;
}

export interface AssetTree {
  uuid: string;
  name: string;
  type: string;
  rootUuid: string;
  hasChildren?: boolean;
  children: AssetTree[];
  permission?: string;
  owner?: {
    company?: string;
    fullname?: string;
    uuid?: string;
  };
  productName?: string;
  productPictureUrl?: string;
  profilePicture?: string;
  extern?: boolean;
  crm365?: boolean;
  snapshotId?: string;
  customerNumber?: string;
  subsidiaryCode?: string;
}

export interface GetAssetTreesResponse {
  root: AssetTree;
  inherited: AssetTree[];
}

export interface GetAssetTreesResponseV2 {
  owned: AssetTree[];
  inherited: AssetTree[];
}

export interface AssetMetadata {
  assetType: number;
  sourceType?: string;
  vendor?: string;
  productName?: string;
  productPictureUrl?: string;
  partNumber?: string;
  customName?: string;
  serialNumber?: string;
  description?: string;
}

export interface AssetMetadataV2 {
  assetType: number;
  sourceType?: string;
  parentUuid?: string;
  vendor?: string;
  productName?: string;
  productPictureUrl?: string;
  partNumber?: string;
  customName?: string;
  serialNumber?: string;
  description?: string;
  imo?: string;
  firmwareVersion?: string;
  deploymentDate?: number | null;
}

export interface AssetUpdateRequest {
  // general properties
  customName?: string;
  description?: string;
  typeId?: number;
  deploymentDate?: number | null;

  // only for type == device
  vendor?: string;
  productName?: string;
  partNumber?: string;
  serialNumber?: string;
  productPictureUrl?: string;
  firmwareVersion?: string;

  // only for type == ship
  imo?: string;
}

export interface EventTable {
  date;
  event;
  triggeredBy;
}

export interface AssetBreadcrumb {
  name?: string;
  productName?: string;
  uuid: string;
  type: string;
}

export function isBreadcrumb(br: any): br is AssetBreadcrumb {
  return br.uuid && br.typeId;
}

export interface TranslatedAssetNames {
  assetTypeName: string;
  defaultAssetName: string;
}

export interface AssetLoadingResponse {
  asset?: GetAssetResponse;
  error?: Error;
}

export interface AssetName {
  productName: string;
  customName: string;
}
