import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import { LanguageService, LicensingService, TreeService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ExportCsvModalComponent } from '../modals';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { IconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-menu-item-asset-export-csv',
  templateUrl: '../menu/menu-item/menu-item.component.html',
  styleUrls: ['../menu/menu-item/menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent],
})
export class MenuItemAssetExportCsvComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
    private dialogService: DialogService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('format_list_bulleted', 'assetDetails.menu.asset-export');
  }

  static override isViableOption(asset: GetAssetResponse | AssetItem | undefined): boolean {
    if (!asset) {
      return false;
    }
    return true;
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    this.dialogService.open(ExportCsvModalComponent, {
      header: this.languageService.instant('export.sub-tree-export-header'),
      data: {
        asset: this.asset,
      },
      closable: false,
    });
  }
}
