import { Environment } from '@assethub/shared/models';

export const environment: Environment = {
  dynamicSettings: false,
  production: false,
  title: 'SICK AssetHub <dev>',
  debug: 'AssetHub:*',

  apiUrl: 'https://api.dev.sickag.cloud/assethub',
  apiUrlEcs: 'https://api.assethub.dev.sickag.cloud',
  swsApiKey: 'P5RGDjSrbSQHEAAvvzpf1h7Yv2zRJq1EJwR7GYua',
  iccUrl: 'https://api.dev.sickag.cloud/icc',
  icc2Url: 'https://api.dev.sickag.cloud/icc-2',
  iccWebsocketUrl: 'wss://ws.dev.sickag.cloud/icc',
  liveConnectUrl: 'https://api.dev.sickag.cloud/liveconnect',
  configBackendUrl: 'https://api.dev.sickag.cloud/config-repo',
  configBackendApiKey: 'd8c2cbfd-ec08-4dd0-9bea-c4dc59e33cca',
  integrationLayerProxyUrl: 'https://api.int.sickag.cloud/integration',
  sm365Url: 'https://api.dev.sickag.cloud/servicemodule-365',

  integrationTestCredentials: {
    assetHubClientId: 'SWS-MyDevice',
    assetHubClientSecret: '4a17994a-3770-4d31-80b9-117a0c44e8f8',
    assetHubTestUser: 'test215.assethub@sick.de',
    assetHubTestUserPassword: 'Eb4NbDNob34yif28',
  },
  oidc: {
    // Url of the Identity Provider
    issuer: 'https://id.test.sick.com/auth/realms/sickservices',
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'SWS-MyDevice',
  },
  oidcPassChange: 'https://id.test.sick.com/auth/realms/sickservices/account/#/credentials',
  oidcProfile: 'https://id.test.sick.com/auth/realms/sickservices/account/',

  dtagBackendUrl: 'https://dtag.cloud.sick.com',

  hereWeGoApiKey: 'duim_jtBew2ir6IKpv8JgFSh6IeAZlgtGvnPDTIMXKA',

  websocketUrl: 'wss://ws.dev.sickag.cloud/assethub',

  ignoreConsentFailure: true,
  digitalServiceCatalogUrl: {
    basePath: 'https://test-cloud.sick.com',
    serviceIds: {
      basic: 'd44e42f0-a221-11e9-b853-0d2d2586cf89',
      liveConnect: '14646c60-044d-11ea-abfc-79c4f8a65a4d',
      serviceModule: '689f2210-debf-11e9-9168-a3982b7c93c1',
    },
  },

  groupManagement: {
    baseUrl: 'https://groups.test-cloud.sick.com',
    apiUrl: 'https://groups.test-cloud.sick.com/api/',
  },

  sickUrl: 'https://www.sick.com/',
  additionalLanguages: ['de'],
  enableCustomerProductQuery: true,
  enableCreateTreeByUser: false,
  productFinderInterface: 'search',
  monitoringBoxServiceUrl: 'https://smartservice.test.sick.com/cm/api/v1',
  artifactRepositoryUrl: `https://api.dev.sickag.cloud/artifact-repository`,
  apppoolBackend: 'https://api.apppool.dev-cloud.sick.com/apppool',
  apppoolFrontend: 'https://apppool.dev-cloud.sick.com',

  featureToggles: {
    ASSET_DETAILS_DEBUG_PANEL: true,
    GUI_CHECK_PAGE: true,
    TSD: true,
    ASSET_TREE_LIVE_STATE: true,
    DASHBOARD_MOCKUP_WIDGETS: true,
    SERVICE_COVERAGE_WIDGET: false,
    EXPORT_AASX_FILE: true,
    TTS_KEY_DECODER: true,
    SOFTWARE_STATE: true,
    PHASED_OUT_STATE: true,
    APPS_MENU_IN_HEADER: true,
  },
};
