import { NgIf } from '@angular/common';
import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetItem, GetAssetResponse } from '@assethub/shared/models';
import {
  AssetSelectionService,
  ConfirmationService,
  LanguageService,
  LicensingService,
  TrashService,
  TreeService,
} from '@assethub/shared/services';
import { FeatureToggleEvalFn } from '@assethub/shared/utils';
import { MessageService } from 'primeng/api';
import { catchError, finalize, tap } from 'rxjs';
import { IconComponent } from '../icon/icon.component';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';

@Component({
  selector: 'app-menu-item-asset-delete',
  templateUrl: '../menu/menu-item/menu-item.component.html',
  styleUrls: ['../menu/menu-item/menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent],
})
export class MenuItemAssetDeleteComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private assetSelectionService: AssetSelectionService,
    private trashService: TrashService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('delete', 'assetDetails.menu.delete');
  }

  static override isViableOption(
    asset: GetAssetResponse | AssetItem | undefined,
    opt: {
      featureToggleActive: FeatureToggleEvalFn;
    },
  ): boolean {
    if (BasicMenuItemAssetComponent.isManagedExternally(asset)) {
      return false;
    }
    if (BasicMenuItemAssetComponent.isRoot(asset)) {
      if (opt.featureToggleActive('DELETE_TREE_DISABLED')) {
        return false;
      }
      return BasicMenuItemAssetComponent.isAdministrable(asset);
    }
    return BasicMenuItemAssetComponent.isWritable(asset);
  }

  protected override getReason(opt: { featureToggleActive: FeatureToggleEvalFn }): string {
    if (BasicMenuItemAssetComponent.isManagedExternally(this.asset)) {
      return 'managedExternally';
    }
    if (BasicMenuItemAssetComponent.isRoot(this.asset)) {
      if (opt.featureToggleActive('DELETE_TREE_DISABLED')) {
        return 'deleteTreeNotAllowed';
      }
      if (!BasicMenuItemAssetComponent.isAdministrable(this.asset)) {
        return 'deleteTreeRequiresAdmin';
      }
    }
    if (!BasicMenuItemAssetComponent.isWritable(this.asset)) {
      return 'deleteRequiresWrite';
    }
    return '';
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    const assetUuid = this.asset.uuid;
    this.assetSelectionService.markNode(assetUuid);
    const isRoot = BasicMenuItemAssetComponent.isRoot(this.asset);
    const notificationKey = isRoot ? 'toasts.tree.trashed' : 'toasts.asset.trashed';
    this.confirmationService.confirm({
      translationKey: 'confirm.asset.moveToTrash',
      accept: () =>
        this.trashService.moveToTrash(assetUuid).pipe(
          tap(() => {
            this.forgetAsset(assetUuid);
            this.messageService.add({
              severity: 'info',
              summary: notificationKey,
              life: 10000,
            });
          }),
          catchError(fail => {
            this.messageService.add({
              severity: 'error',
              summary: 'toasts.asset.move-trash-asset-error',
              life: 10000,
            });
            throw fail;
          }),
          finalize(() => this.assetSelectionService.markNode()),
        ),
      reject: () => this.assetSelectionService.markNode(),
    });
  }
}
