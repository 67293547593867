<div class="input-container">
  <span class="p-float-label text-field">
    <input type="text" pInputText [placeholder]="fileName" disabled required />
    <label>{{ 'assetConfigurations.filename' | translate }}</label>
  </span>
  <span class="p-float-label text-field">
    <input
      type="text"
      pInputText
      autocomplete="off"
      [(ngModel)]="customName"
      [disabled]="disableControls"
    />
    <label>{{ 'assetConfigurations.name' | translate }}</label>
  </span>
  <app-label-picker
    [(ngModel)]="labels"
    (completeMethod)="suggestLabels($event)"
    [suggestions]="suggestions"
    [disallowNewLabels]="featureToggleActive('DISALLOW_NEW_CONFIG_LABELS')"
  ></app-label-picker>
</div>
<div class="dialog-footer">
  @if (disableControls) {
    <p-progressBar class="progress-bar" mode="indeterminate"></p-progressBar>
  }
  <button
    pButton
    type="button"
    class="ui-button-outline"
    label="{{ 'buttons.cancel' | translate }}"
    (click)="cancel()"
    [disabled]="disableControls"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-primary"
    [label]="acceptLabel"
    (click)="accept()"
    [disabled]="disableControls"
  ></button>
</div>
