export enum IccRegistrationState {
  // Initial state when lookup not returned
  UNKNOWN = 'UNKNOWN',

  // Device is not registered, but type well known and softapprovable
  UNREGISTERED = 'UNREGISTERED',

  // Pairing (formerly known as softapproval) ongoing
  IN_SOFTAPPROVAL = 'IN_SOFTAPPROVAL',

  // Fully registered
  REGISTERED = 'REGISTERED',

  // Peered device
  PEERED = 'PEERED',
}

export enum IccState {
  VOID,
  OFFLINE,
  ONLINE,
}

export interface IccDeviceInfo {
  deviceUuid?: string;
  gatewayUuid?: string;
  state: IccRegistrationState;
  peers?: string[];
}

export interface IccConnection {
  id: string;
  connectedAt: Date | string;
  connectedUntil: Date | string | null;
  duration: number | undefined;
}
