<!-- Core header display -->
<div class="header">
  <img src="assets/images/sick-logo-small.svg" class="logo" alt="Sick AG" />
  <div [class]="searchExpanded ? 'application_title_invisible' : 'application_title'">
    {{ 'toolbar.assetHub' | translate }}
  </div>
  <div [class]="searchExpanded ? 'divider_invisible' : 'divider'"></div>
  <app-global-search
    class="search"
    (expandStateChanged)="expandChanged($event)"
  ></app-global-search>
  @if (!FEATURE_TOGGLE_FEEDBACK_BUTTON_HIDDEN) {
    <button
      pButton
      type="button"
      class=""
      icon="pi pi-large pi-feedback"
      title="{{ 'header.feedback' | translate }}"
      (click)="onShowFeedbackModal()"
    ></button>
  }
  <app-menu
    icon="custom_world"
    iconSize="28"
    offset="9"
    title="{{ 'toolbar.language' | translate }}"
  >
    @for (lang of languages; track lang) {
      <app-menu-item
        [label]="lang.label"
        [disabled]="lang.disabled || false"
        [icon]="lang.code === language ? 'pi pi-radio-button-checked' : ''"
        (selected)="onLanguageChange(lang.code)"
      ></app-menu-item>
    }
  </app-menu>
  @if (FEATURE_TOGGLE_APPS_MENU_IN_HEADER) {
    <app-menu icon="apps" iconSize="28" offset="9" title="{{ 'header.apps' | translate }}">
      @for (app of apps; track $index) {
        <app-menu-item
          [label]="app.label"
          [icon]="app.icon"
          (selected)="onOpenInNewWindow(app.url)"
        >
        </app-menu-item>
      }
    </app-menu>
  }
  <app-menu
    icon="custom_account_check"
    iconSize="28"
    offset="9"
    title="{{ 'header.user' | translate }}"
  >
    <app-menu-item
      [label]="'toolbar.sickProfile' | translate"
      (selected)="onShowProfile()"
    ></app-menu-item>
    <app-menu-item
      [label]="'toolbar.changePassword' | translate"
      (selected)="onChangePassword()"
    ></app-menu-item>
    <app-menu-item [label]="'toolbar.logout' | translate" (selected)="onLogout()"></app-menu-item>
  </app-menu>
  <app-menu iconSize="28" offset="9" title="{{ 'header.more' | translate }}">
    <app-menu-item [label]="'toolbar.releaseNotes' | translate" (selected)="onReleaseNotes()">
    </app-menu-item>
    <app-menu-item
      [label]="'toolbar.licenses' | translate"
      (selected)="onShowLicenses()"
    ></app-menu-item>
    <app-menu-item [label]="'toolbar.trashBin' | translate" (selected)="onTrashBin()">
    </app-menu-item>
    @if (FEATURE_TOGGLE_GUI_CHECK_PAGE) {
      <app-menu-item
        [label]="'toolbar.guiCheck' | translate"
        (selected)="onGuiCheck()"
      ></app-menu-item>
    }
  </app-menu>
</div>
