import { FEATURE_TOGGLE } from '@assethub/shared/utils';
import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { FIRMWARE_STATE_ICON, FirmwareState } from '@assethub/shared/models/firmware-state';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { IconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-firmware-state-icon',
  templateUrl: './firmware-state-icon.component.html',
  styleUrls: ['./firmware-state-icon.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent, TooltipComponent, TranslateModule, SafeHtmlPipe],
})
export class FirmwareStateIconComponent implements OnInit, OnChanges {
  @Input() firmwareState: FirmwareState = 'UNKNOWN';
  @Input() disableTooltip = false;

  color: string;
  icon: string;
  description: string;
  featureToggleActive = inject(FEATURE_TOGGLE);

  constructor() {
    this.update();
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  private update(): void {
    const icon = FIRMWARE_STATE_ICON[this.firmwareState];
    this.icon = icon.shape;
    this.color = icon.color;
    this.description = `asset.firmware.state.${this.firmwareState}.description`;
  }
}
