import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { ConfirmationService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ENVIRONMENT } from '../models';
import {
  DocumentUpload,
  DocumentUploadFeedback,
  DocumentUploadMeta,
  PagedDocumentResponse,
  PersistedDocument,
} from '../models/asset-document';
import { QueryFilter } from '../models/query-filter/filter';
import { HttpFileService } from './http-file.service';

interface AssetUploads {
  assetUuid: string;
  remaining: number;
  total: number;
  notify: BehaviorSubject<DocumentUploadFeedback>;
  subscriptions: Subscription[];
  files: File[];
  docUploadMeta: (DocumentUploadMeta | null)[];
}

@Injectable({ providedIn: 'root' })
export class AssetDocumentService implements OnDestroy {
  private pendingUploads: Map<string, AssetUploads>;
  private logger = new Logger(this.constructor.name);
  private apiUrl = inject(ENVIRONMENT).apiUrl;

  public constructor(
    private httpClient: HttpClient,
    private httpFileService: HttpFileService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    this.pendingUploads = new Map<string, AssetUploads>();
  }

  public ngOnDestroy() {
    const assets = this.pendingUploads.keys();
    for (let current = assets.next(); !current.done; current = assets.next()) {
      this.cleanupUploads(current.value);
    }
    this.pendingUploads.clear();
  }

  public uploadFiles(assetUuid: string, files: File[]): BehaviorSubject<DocumentUploadFeedback> {
    const info: AssetUploads = {
      assetUuid,
      remaining: files.length,
      total: files.length,
      notify: new BehaviorSubject<DocumentUploadFeedback>({
        remaining: files.length,
        doc: null,
      }),
      subscriptions: [],
      files,
      docUploadMeta: [],
    };
    info.subscriptions.push(
      this.getPresignedUrls(assetUuid, files).subscribe({
        next: docUploadMeta => {
          info.docUploadMeta = docUploadMeta;
          const duplicates = docUploadMeta.filter(x => x.documentUpload.duplicate);
          if (duplicates.length > 0) {
            this.confirmationService.confirm({
              translationKey: 'documents.fileUpload.duplicateWarning',
              accept: () => {
                for (let i = 0; i < files.length; i++) {
                  this.singleFileUpload(info, i);
                }
              },
              reject: () => {
                docUploadMeta.forEach(x => {
                  this.remove(x.documentUpload.uuid, info.assetUuid).subscribe();
                });
                this.onCancelUpload(info);
              },
            });
          } else {
            for (let i = 0; i < files.length; i++) {
              this.singleFileUpload(info, i);
            }
          }
        },
        error: () => {
          // failed to get presigned upload urls -> terminate the whole thing at once
          this.onCancelUpload(info);
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.file.upload-general-failure',
            life: 10000,
          });
        },
      }),
    );
    this.pendingUploads.set(assetUuid, info);
    return info.notify;
  }

  public getPendingUploads(assetUuid: string) {
    const info = this.pendingUploads.get(assetUuid);
    if (!info) {
      return undefined;
    }
    return {
      remaining: info.remaining,
      total: info.total,
      notify: info.notify,
    };
  }

  public fetchDocuments(
    assetUuid: string,
    queryParams?: QueryFilter,
  ): Observable<PagedDocumentResponse> {
    let pathParams:
      | {
          [param: string]: string | number;
        }
      | undefined;

    if (queryParams) {
      pathParams = {};
      if (queryParams.orderBy) {
        pathParams.orderBy = queryParams.orderBy;
      }
      if (queryParams.sortDirection) {
        pathParams.sortDirection = queryParams.sortDirection;
      }
      if (queryParams.size) {
        pathParams.perPage = queryParams.size;
      }
      if (queryParams.start) {
        pathParams.offset = queryParams.start;
      }
    }

    return this.httpClient.get<PagedDocumentResponse>(
      `${this.apiUrl}/asset/${assetUuid}/documents`,
      { params: pathParams },
    );
  }

  public getPresignedUrls(uuid: string, files: File[]): Observable<DocumentUploadMeta[]> {
    const docMetas: DocumentUpload[] = [];
    files.forEach(file => {
      docMetas.push({ fileName: file.name, size: file.size });
    });

    return this.httpClient.post<DocumentUploadMeta[]>(
      `${this.apiUrl}/asset/${uuid}/document-upload-urls`,
      {
        documents: docMetas,
      },
    );
  }

  public downloadDocument(assetUuid: string, documentUuid: string): Observable<ArrayBuffer> {
    return this.httpClient
      .get<string>(`${this.apiUrl}/asset/${assetUuid}/document/${documentUuid}/presigned-url`)
      .pipe(mergeMap(url => this.httpFileService.downloadBinary(url)));
  }

  public remove(uuid: string, assetUuid: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/asset/${assetUuid}/document/${uuid}`);
  }

  private cleanupUploads(assetUuid: string) {
    const uploads = this.pendingUploads.get(assetUuid);
    if (!uploads) {
      return;
    }
    for (const sub of uploads.subscriptions) {
      sub.unsubscribe();
    }
    this.pendingUploads.delete(assetUuid);
  }

  private singleFileUpload(info: AssetUploads, index: number) {
    const formData = new FormData();
    const presignedUrl = info.docUploadMeta[index]?.presignedUrl;
    if (!presignedUrl) {
      return;
    }

    Object.keys(presignedUrl.fields).forEach(key => {
      formData.append(key, presignedUrl.fields[key]);
    });
    // Actual file has to be appended last.
    formData.append('file', info.files[index]);

    info.subscriptions.push(
      this.httpFileService.uploadFormData(presignedUrl.url, formData).subscribe({
        next: () => {
          // ok
          this.logger.debug('upload of', info.files[index].name, 'done');
          this.onUploadDone(info);
        },
        error: err => {
          // failed to upload
          this.onFailedUpload(info, index, 'toasts.file.upload-failed');
          this.logger.error('error', err);
        },
      }),
    );
  }

  private onUploadDone(info: AssetUploads) {
    const index = info.docUploadMeta.length - info.remaining;
    const docInfo = info.docUploadMeta[index];
    let docToAdd: PersistedDocument | null = null;

    if (docInfo) {
      docToAdd = {
        fileName: docInfo.documentUpload.fileName,
        size: docInfo.documentUpload.size,
        uuid: docInfo.documentUpload.uuid,
        lastUpdated: new Date(),
      };
    }

    info.remaining--;
    info.notify.next({ remaining: info.remaining, doc: docToAdd });
    if (!info.remaining) {
      this.cleanupUploads(info.assetUuid);
      info.notify.complete();
    }
  }

  private onFailedUpload(info: AssetUploads, index: number, summary: string) {
    info.docUploadMeta[index] = null;
    this.onUploadDone(info);
    this.messageService.add({
      severity: 'error',
      summary,
      life: 10000,
      data: {
        filename: info.files[index].name,
      },
    });
  }

  private onCancelUpload(info: AssetUploads) {
    info.remaining = 0;
    info.notify.next({ remaining: info.remaining, doc: null });
    info.notify.complete();
    this.cleanupUploads(info.assetUuid);
  }
}
