<p-dialog
  header="{{ 'asset.firmware.invalid.title' | translate }}"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="displayInvalidFirmwareDialog"
  [modal]="true"
  [blockScroll]="true"
  [dismissableMask]="true"
  appendTo="body"
  [style]="{ 'max-width': 'inherit', 'line-height': 2 }"
>
  <div
    class="formatted-text"
    [innerHtml]="'asset.firmware.invalid.description' | translate | safeHtml"
  ></div>
</p-dialog>

<div class="asset-header" *ngIf="asset">
  <div class="asset-header-image">
    <div class="image-frame image-loading" *ngIf="updating || !profilePicture.ready">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="image-frame" *ngIf="!updating && profilePicture.ready">
      <ng-container
        *ngIf="profilePicture.normal; then customImage; else defaultImage"
      ></ng-container>
      <ng-template #customImage>
        <div
          class="main-image-frame cursor-pointer"
          [ngStyle]="{
            'background-image': 'url(' + (profilePicture.normal! | completeUrl) + ')',
          }"
          (click)="openPicture($event)"
        >
          <div class="overlay">
            <button
              *ngIf="!editAsset"
              pButton
              type="button"
              class="ui-button-bare-neutral"
              icon="pi pi-pencil"
              (click)="navigateToImageAccordion($event)"
            ></button>
          </div>
          <!-- 404 detector -->
          <img
            style="display: none"
            [src]="sanitize(profilePicture.normal! | completeUrl)"
            (error)="fallbackToIcon()"
          />
        </div>
      </ng-template>
      <ng-template #defaultImage>
        <div class="main-image-frame">
          <img src="{{ defaultPictureUrl }}" />
          <div class="overlay">
            <button
              *ngIf="!editAsset"
              pButton
              type="button"
              class="ui-button-bare-neutral"
              icon="pi pi-pencil"
              (click)="navigateToImageAccordion($event)"
            ></button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="asset" class="asset-header-details">
    <!-- Asset Title and Options -->
    <div class="asset-title-bar">
      <ng-container *ngIf="!editAsset; else titleEditable">
        <h2 class="asset-title">
          {{ title || defaultTitle }}
        </h2>
      </ng-container>
      <ng-template #titleEditable>
        <app-text-field
          class="asset-title editable"
          [placeholder]="defaultTitle"
          [(ngModel)]="title"
          [disabled]="updating"
        ></app-text-field>
      </ng-template>
      <div class="options" *ngIf="!editAsset">
        <ng-container *ngTemplateOutlet="statusBar ? statusBar : emptyTemplate"></ng-container>
        <div class="asset-map-button">
          <button
            pButton
            type="button"
            class="ui-button-bare"
            icon="pi pi-map"
            (click)="navigateToMap()"
          ></button>
        </div>
        <app-asset-option-menu
          [asset]="asset"
          (editAsset)="enterEditMode()"
          id="option-expandable-menu"
        >
          <ng-container *ngTemplateOutlet="optionMenu ? optionMenu : emptyTemplate"></ng-container>
        </app-asset-option-menu>
      </div>
    </div>
    <!-- Separator -->
    <hr class="title-separator" />
    <!-- Asset meta data -->
    <div class="asset-metadata-bar">
      <ng-template #assetTypeTemplate>
        <div>
          <span>{{ 'asset.type' | translate }}</span>
        </div>
        <div [ngClass]="{ 'edit-mode-enabled': editAsset && assetTypeChangeable }">
          <div
            class="asset-type-container"
            *ngIf="!editAsset || !assetTypeChangeable; else typeEditable"
          >
            <span class="shrinkable-prop" id="type-description">{{
              'assetTypes.' + currentTypeAsString | translate
            }}</span>
            <img id="type-image" [src]="'assets/images/' + currentTypeAsString + '.svg'" />
          </div>
          <ng-template #typeEditable>
            <div class="asset-type-container">
              <p-dropdown
                id="assetType"
                name="assetType"
                [(ngModel)]="typeSelected"
                [options]="assetTypes"
                scrollHeight="300px"
                [overlayOptions]="{
                  showTransitionOptions: '0ms',
                  hideTransitionOptions: '0ms',
                }"
                (onChange)="typeChanged($event)"
                [disabled]="updating"
              >
                <ng-template let-type pTemplate="selectedItem">
                  <img src="{{ type.icon }}" />
                  <span class="asset-type-label">{{ type.label | translate }}</span>
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <img src="{{ type.icon }}" />
                  <span class="asset-type-label">{{ type.label | translate }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </ng-template>
        </div>
      </ng-template>

      <ng-template #shipImoTemplate>
        <div>
          <span>{{ 'asset.imo-number' | translate }}</span>
        </div>
        <div [ngClass]="{ 'edit-mode-enabled': editAsset }">
          <ng-container *ngIf="!editAsset; else imoEditable">
            <span
              #expandable
              (click)="expandable.classList.toggle('expanded')"
              class="shrinkable-prop"
              >{{ shipImo || '-' }}</span
            ></ng-container
          >
          <ng-template #imoEditable>
            <app-text-field [(ngModel)]="shipImo" [disabled]="updating"></app-text-field>
          </ng-template>
        </div>
      </ng-template>

      <ng-template #vendorTemplate>
        <div>
          <span>{{ 'asset.vendor' | translate }}</span>
        </div>
        <div *ngIf="!editAsset || vendorUpdatable === false; else vendorEditable">
          <span
            class="shrinkable-prop"
            #expandable
            (click)="expandable.classList.toggle('expanded')"
            >{{ vendor || '-' }}</span
          >
        </div>
        <ng-template #vendorEditable>
          <div class="edit-mode-enabled">
            <app-text-field [(ngModel)]="vendor" [disabled]="updating"></app-text-field>
          </div>
        </ng-template>
      </ng-template>

      <ng-template #serialNumberTemplate>
        <div>
          <span>{{ 'asset.serial-number' | translate }}</span>
        </div>
        <div *ngIf="!editAsset || serialNumberUpdatable === false; else serialNoEditable">
          <div class="serial-number-container">
            <span
              class="shrinkable-prop"
              #expandable
              (click)="expandable.classList.toggle('expanded')"
              >{{ serialNumber || '-' }}</span
            >
          </div>
        </div>
        <ng-template #serialNoEditable>
          <div class="edit-mode-enabled">
            <app-text-field [(ngModel)]="serialNumber" [disabled]="updating"></app-text-field>
          </div>
        </ng-template>
      </ng-template>

      <ng-template #productNameTemplate>
        <div>
          <span>{{ 'asset.product-name' | translate }}</span>
        </div>
        <div [ngClass]="{ 'edit-mode-enabled': editAsset }">
          <ng-container
            *ngIf="!editAsset || productNameUpdatable === false; else productNameEditable"
          >
            <div
              class="shrinkable-prop"
              #expandable
              (click)="expandable.classList.toggle('expanded')"
            >
              <span *ngIf="canShowShopLink()" class="link-sick-shop">
                <a
                  title="{{ 'assetDetails.linkSickShop' | translate }}"
                  href="{{ shopPath | completeUrl }}"
                  target="_blank"
                  rel="noopener"
                  class="shrinkable-prop"
                  #expandable
                  (click)="expandable.classList.toggle('expanded')"
                  >{{ productName || '-' }}</a
                >
              </span>
              <span *ngIf="!canShowShopLink()" class="shrinkable-prop">{{
                productName || '-'
              }}</span>
            </div>
          </ng-container>

          <ng-template #productNameEditable>
            <app-text-field [(ngModel)]="productName" [disabled]="updating"></app-text-field>
          </ng-template>
        </div>
      </ng-template>

      <ng-template #partNumberTemplate>
        <div>
          <span>{{ 'asset.part-number' | translate }}</span>
        </div>
        <div *ngIf="!editAsset || partNumberUpdatable === false; else partNumberEditable">
          <span
            class="shrinkable-prop"
            #expandable
            (click)="expandable.classList.toggle('expanded')"
            >{{ partNumber || '-' }}</span
          >
        </div>
        <ng-template #partNumberEditable>
          <div class="edit-mode-enabled">
            <app-text-field [(ngModel)]="partNumber" [disabled]="updating"></app-text-field>
          </div>
        </ng-template>
      </ng-template>

      <ng-template #firmwareVersionTemplate>
        <div>
          <span>{{ 'asset.firmware.version' | translate }}</span>
        </div>
        <div *ngIf="!editAsset; else firmwareVersionEditable">
          <div class="firmware-field">
            <span
              class="shrinkable-prop"
              #expandable
              (click)="expandable.classList.toggle('expanded')"
              >{{ firmwareVersion || '-' }}
            </span>
            <app-firmware-state-icon [firmwareState]="firmwareState"></app-firmware-state-icon>
          </div>
        </div>
        <ng-template #firmwareVersionEditable>
          <div class="edit-mode-enabled">
            <app-text-field
              [(ngModel)]="firmwareVersion"
              [disabled]="updating"
              (ngModelChange)="validateVersion()"
              (blurred)="onVersionLeaveFocus()"
              [handleErrorIconClick]="showVersionHelp"
              [error]="showVersionErrorIcon"
            ></app-text-field>
          </div>
        </ng-template>
      </ng-template>

      <ng-template #deploymentDateTemplate>
        <div>
          <span>{{ 'asset.deployment-date.' + deploymentDateLanguageKey | translate }}</span>
        </div>
        <div *ngIf="!editAsset; else deploymentDateEditable">
          <div class="deployment-date-field">
            <span
              class="shrinkable-prop"
              #expandable
              (click)="expandable.classList.toggle('expanded')"
              >{{
                deploymentDateTimestamp
                  ? (deploymentDateTimestamp * 1000 | localizedDate: 'mediumDate')
                  : '-'
              }}
            </span>
          </div>
        </div>
        <ng-template #deploymentDateEditable>
          <div class="edit-mode-enabled">
            <app-date-field [disabled]="updating" [(ngModel)]="deploymentDate"> </app-date-field>
          </div>
        </ng-template>
      </ng-template>

      <ng-template #updatedAtTemplate>
        <ng-container *ngIf="asset.inventoryDetails && asset.inventoryDetails.updatedTime">
          <div>
            <span>{{ 'asset.updated-at' | translate }}</span>
          </div>
          <div>
            <span
              class="shrinkable-prop"
              #expandable
              (click)="expandable.classList.toggle('expanded')"
              >{{ asset.inventoryDetails.updatedTime * 1000 | localizedDate: 'medium' }}</span
            >
          </div>
        </ng-container>
      </ng-template>
      <ng-container>
        <!-- Metadata table - Single column style -->
        <div class="asset-prop-table">
          <ng-container *ngIf="isProduct()">
            <!-- Vendor data -->
            <ng-container *ngTemplateOutlet="vendorTemplate"></ng-container>
          </ng-container>
          <!-- Asset Type -->
          <ng-container *ngTemplateOutlet="assetTypeTemplate"></ng-container>
          <!-- Regular fields in common for all assets -->
          <ng-container *ngIf="isProduct()">
            <!-- Product name data -->
            <ng-container *ngTemplateOutlet="productNameTemplate"></ng-container>
            <!-- part number data -->
            <ng-container *ngTemplateOutlet="partNumberTemplate"></ng-container>
            <!-- Serial no -->
            <ng-container *ngIf="hasSerialNumber()">
              <ng-container *ngTemplateOutlet="serialNumberTemplate"></ng-container>
            </ng-container>
            <!-- Firmware version -->
            <ng-container *ngTemplateOutlet="firmwareVersionTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="isTypeShip()">
            <!-- IMO -->
            <ng-container *ngTemplateOutlet="shipImoTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="showDeploymentDateField">
            <!-- deployment Date -->
            <ng-container *ngTemplateOutlet="deploymentDateTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="asset.inventoryDetails && asset.inventoryDetails.updatedTime">
            <!-- Updated at timestamp -->
            <ng-container *ngTemplateOutlet="updatedAtTemplate"></ng-container>
          </ng-container>
        </div>
      </ng-container>
      <!-- Phased out warn -->
      <app-callout
        severity="warn"
        *ngIf="!editAsset && phaseOutWarningMsg && featureToggleActive('PHASED_OUT_STATE')"
      >
        <span>{{ phaseOutWarningMsg | translate }}</span
        ><span *ngIf="successor">
          {{ 'assetDetails.successor' | translate }}
          <a
            href="{{ successor.Target.PartNumber | completeUrl }}"
            target="_blank"
            rel="noopener"
            >{{ successor.Target.Name }}</a
          ></span
        ></app-callout
      >

      <!-- Separator -->
      <hr *ngIf="editAsset" class="button-separator" />
      <!-- Edit mode options -->
      <app-save-cancel-options
        *ngIf="editAsset"
        [cancelDisabled]="updating"
        [saveDisabled]="updating"
        (cancelClick)="reset()"
        (saveClick)="saveChangesToAsset()"
      ></app-save-cancel-options>
    </div>
  </div>
</div>
<ng-template #emptyTemplate></ng-template>
