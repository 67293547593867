import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { MenuComponent } from '../menu/menu/menu.component';
import { SDSColors } from '../../utils';
import { NgStyle } from '@angular/common';
import { TooltipService } from '../../services/tooltip.service';

@UntilDestroy()
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [forwardRef(() => MenuComponent), NgStyle],
})
export class TooltipComponent implements OnInit, OnChanges {
  @Input() icon = 'info_outline';
  @Input() iconColor = '';
  @Input() iconSize: string | number = 24;
  @Input() label = '';
  @Input() width = '175px';
  @Input() backgroundColor: string = SDSColors.background;
  @Input() tooltipStyle: { [k: string]: string } = {};

  @ViewChild('menu')
  menu?: MenuComponent;

  _tooltipStyle: { [k: string]: string } = {};

  private subscription?: Subscription;

  constructor(private tooltipService: TooltipService) {}

  ngOnInit(): void {
    this._tooltipStyle = { width: this.width, padding: '16px', ...this.tooltipStyle };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tooltipStyle || changes.width) {
      this._tooltipStyle = { width: this.width, ...this.tooltipStyle };
    }
  }

  onShowMenu(): void {
    // When this tooltip opens, any other open tooltip automatically closes because
    // every show and hide operation on any menu and submenu triggers the tooltip-service.
    this.subscription = this.tooltipService.hideAll$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.hide());
  }

  hide(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
    this.menu?.hide();
  }
}
