<app-navigation-spinner></app-navigation-spinner>

<div class="main">
  <div *ngIf="reason === 'subscription'" class="error">
    <div class="messagebox">
      <div class="heading">
        <app-icon shape="info"></app-icon>
        {{ 'error.subscription.title_' + subscription | translate }}
      </div>
      <div class="message">
        {{ 'error.subscription.beginMessage' | translate }}
        <a href="{{ serviceCatalogUrl }}">{{ 'error.subscription.link' | translate }}</a>
        {{ 'error.subscription.endMessage' | translate }}
      </div>
    </div>
  </div>

  <app-no-asset-selected
    *ngIf="reason === 'assetnotfound'"
    serviceName="{{ 'error.assetNotFound.title' | translate }}"
    callToSelect="{{ 'error.assetNotFound.message' | translate }}"
    icon="sentiment_dissatisfied"
  >
  </app-no-asset-selected>

  <app-no-asset-selected
    *ngIf="reason === 'generic'"
    serviceName="{{ 'error.generic.title' | translate }}"
    callToSelect="{{ 'error.generic.message' | translate }}"
    icon="sentiment_dissatisfied"
  >
  </app-no-asset-selected>

  <app-no-asset-selected
    *ngIf="reason === 'authentication'"
    serviceName="{{ 'error.authentication.title' | translate }}"
    callToSelect="{{ 'error.authentication.message' | translate }}"
    icon="sentiment_dissatisfied"
  >
  </app-no-asset-selected>
</div>
