<ng-container *ngIf="(permissionEntries.length === 0 && loading) || saving; else noProgressBar">
  <div class="progressbar">
    <p-progressBar mode="indeterminate"> </p-progressBar>
  </div>
</ng-container>

<ng-template #noProgressBar>
  <div class="placeholder"></div>
</ng-template>

<p-table
  [value]="permissionEntries"
  [ngClass]="{ narrow: narrow }"
  breakpoint="0px"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  [paginator]="true"
  [alwaysShowPaginator]="false"
  [totalRecords]="totalRecords"
  [rows]="pageSize"
  [loading]="loading"
  paginatorPosition="bottom"
  (onLazyLoad)="onLazyLoad($event)"
  [(first)]="paginatorStart"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: auto" *ngIf="!isGroupPermission">
        {{ 'assetPermissions.table.user' | translate }}
      </th>
      <th style="width: auto" *ngIf="isGroupPermission">
        {{ 'assetPermissions.table.group' | translate }}
      </th>
      <th style="width: 9em">{{ 'assetPermissions.table.admin' | translate }}</th>
      <th style="width: 9em">{{ 'assetPermissions.table.write' | translate }}</th>
      <th style="width: 6em">{{ 'assetPermissions.table.read' | translate }}</th>
      <th style="width: 3em"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-tablePermissions let-index="rowIndex">
    <tr>
      <td>
        <div *ngIf="!isGroupPermission; then userPermission; else groupPermission"></div>
        <ng-template #userPermission>
          <span class="p-column-title">{{ 'assetPermissions.user' | translate }}</span>
          <label>{{ tablePermissions.label }}</label>
        </ng-template>
        <ng-template #groupPermission>
          <span class="p-column-title">{{ 'assetPermissions.group' | translate }}</span>
          <a
            *ngIf="!!groupManagementUrl"
            class="group-link"
            target="_blank"
            href="{{ groupManagementUrl }}{{ tablePermissions.id }}"
            >{{ tablePermissions.label }}
            <app-icon shape="pi pi-external-link" size="small"></app-icon>
          </a>
        </ng-template>
      </td>
      <td>
        <span class="p-column-title">{{ 'assetPermissions.admin' | translate }}</span>
        <div>
          <p-checkbox
            [(ngModel)]="tablePermissions.admin"
            [binary]="true"
            [disabled]="
              tablePermissions.pAdmin ||
              tablePermissions.pendingAction ||
              tablePermissions.label === login
            "
            (onChange)="onTogglePermission(index, 'admin')"
          ></p-checkbox>
        </div>
      </td>
      <td>
        <span class="p-column-title">{{ 'assetPermissions.write' | translate }}</span>
        <div>
          <p-checkbox
            [(ngModel)]="tablePermissions.write"
            [binary]="true"
            [disabled]="
              tablePermissions.pWrite ||
              tablePermissions.pendingAction ||
              tablePermissions.label === login
            "
            (onChange)="onTogglePermission(index, 'write')"
          ></p-checkbox>
        </div>
      </td>
      <td>
        <span class="p-column-title">{{ 'assetPermissions.read' | translate }}</span>
        <div>
          <p-checkbox
            [ngModel]="tablePermissions.read"
            [binary]="true"
            [disabled]="true"
          ></p-checkbox>
        </div>
      </td>

      <td>
        <span class="p-column-title">{{ 'assetPermissions.delete' | translate }}</span>
        <div>
          <button
            pButton
            type="button"
            class="ui-button-bare-neutral"
            icon="pi pi-trash"
            (click)="onDeletePermission(index)"
            [disabled]="
              tablePermissions.deleteDisabled ||
              tablePermissions.pendingAction ||
              tablePermissions.label === login
            "
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="empty-table-message">
        <app-callout severity="info" *ngIf="!isGroupPermission">{{
          'assetPermissions.noPermissions' | translate
        }}</app-callout>
        <app-callout severity="info" *ngIf="isGroupPermission">{{
          'assetPermissions.noGroupPermissions' | translate
        }}</app-callout>
      </td>
    </tr>
  </ng-template>
</p-table>
