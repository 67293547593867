import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface TranslateWithFallbackParam {
  fallback: string;
}

@Pipe({
  name: 'translateWithFallback',
  standalone: true,
})
export class TranslateWithFallbackPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, params?: TranslateWithFallbackParam): string {
    if (params?.fallback === undefined) {
      throw new Error('Missing fallback parameter for translate');
    }
    const translated = this.translateService.instant(value);

    if (value === translated) {
      return params.fallback;
    }

    return translated;
  }
}
