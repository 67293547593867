import { Logger } from '@assethub/shared/utils';
import { Observable, catchError, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface GetStatusResponse {
  executing: boolean; // True if device is doing something
  progress?: number; // minimum: 0; maximum: 1
  error?: string;
}

const UNABLE_TO_LOGIN = 'Unable to login as service';
const NOT_CONNECTED_TO_DEVICE = 'Not connected to device';
const DEVICE_BUSY = 'Device is busy';

export class DeviceStateError extends Error {
  firmwareIncompatible = false;
  deviceBusyError = false;

  constructor(error?: string) {
    // error can have values = "Not connected to device", "Unable to login as service" or something else
    super(error);
    switch (error) {
      case UNABLE_TO_LOGIN:
      case NOT_CONNECTED_TO_DEVICE:
        this.firmwareIncompatible = true;
        break;
      case DEVICE_BUSY:
        this.deviceBusyError = true;
        break;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteAppManagerService {
  private readonly logger = new Logger(this.constructor.name);
  constructor(private httpClient: HttpClient) {}

  getServiceState(serviceLocation: string): Observable<GetStatusResponse> {
    return this.httpClient
      .get<GetStatusResponse>(`${serviceLocation}/status`, {
        headers: {
          'cache-control': 'max-age=0',
        },
      })
      .pipe(
        tap(res => {
          if (res.error) {
            throw new DeviceStateError(res.error);
          }
        }),
        catchError(ex => {
          this.logger.error('Fetching App Manager Remote service status failed. Details = ', ex);
          throw ex;
        }),
      );
  }

  installApp(serviceLocation: string, downloadUrl: string): Observable<void> {
    return this.httpClient
      .post<void>(
        `${serviceLocation}/restore-async`,
        { url: downloadUrl },
        {
          headers: {
            'cache-control': 'max-age=0',
          },
        },
      )
      .pipe(
        catchError(ex => {
          this.logger.error('App installation failed. Details = ', ex);
          if (ex instanceof HttpErrorResponse && ex.status === 500) {
            throw new DeviceStateError(ex.error?.error);
          }
          throw ex;
        }),
      );
  }

  removeApps(serviceLocation: string, appNames: string[]): Observable<void> {
    return this.httpClient
      .delete<void>(`${serviceLocation}/apps`, {
        headers: {
          'cache-control': 'max-age=0',
        },
        body: {
          apps: appNames.map(name => ({
            name,
          })),
        },
      })
      .pipe(
        catchError(ex => {
          this.logger.error('App removal failed. Details = ', ex);
          if (ex instanceof HttpErrorResponse && ex.status === 500) {
            throw new DeviceStateError(ex.error?.error);
          }
          throw ex;
        }),
      );
  }
}
