import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackupServiceState } from '../models/device-backup-events';

interface ConfigRestoreOptions {
  url: string;
  backupId?: string;
  extUserId?: string;
}

/* Model for Device Backup creation */
export interface CreateBackupParams {
  url: string;
  backupApps?: boolean;
  backupAppParams?: boolean;
  backupPublic?: boolean;
}

@Injectable({ providedIn: 'root' })
export class DeviceBackupService {
  public constructor(private httpClient: HttpClient) {}

  public createAsync(serviceLocation: string, params: CreateBackupParams): Observable<void> {
    return this.httpClient.post<void>(`${serviceLocation}/backup-async`, params);
  }

  public restoreFrom(serviceLocation: string, restoreOpts: ConfigRestoreOptions) {
    return this.httpClient.post<void>(`${serviceLocation}/restore-async`, restoreOpts);
  }

  public getServiceState(serviceLocation: string): Observable<BackupServiceState> {
    return this.httpClient.get<BackupServiceState>(`${serviceLocation}/status`, {
      headers: {
        'cache-control': 'max-age=0',
      },
    });
  }
}
