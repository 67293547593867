@if (featureToggleActive('SOFTWARE_STATE')) {
  <ng-container *ngIf="disableTooltip; else withTooltip">
    <app-icon [shape]="icon" [color]="color"></app-icon>
  </ng-container>

  <ng-template #withTooltip>
    <app-tooltip [icon]="icon" [iconColor]="color" width="300px">
      <div class="formatted-text" [innerHtml]="description | translate | safeHtml"></div>
    </app-tooltip>
  </ng-template>
}
