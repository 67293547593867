import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-no-asset-selected',
  templateUrl: './no-asset-selected.component.html',
  styleUrls: ['./no-asset-selected.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class NoAssetSelectedComponent {
  @Input() serviceName = '';
  @Input() callToSelect = '';
  @Input() icon = '';
}
