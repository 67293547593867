import { AssetTypeMapping, assetTypeMappingToString } from '@assethub/shared/models';

const PAIRABLE_TYPES = [
  AssetTypeMapping.DEVICE,
  AssetTypeMapping.MACHINE_SYSTEM,
  AssetTypeMapping.SOFTWARE,
].map(x => assetTypeMappingToString[x]);

export function isPairable(assetType: string | AssetTypeMapping): boolean {
  if (typeof assetType === 'string') {
    return PAIRABLE_TYPES.includes(assetType);
  }
  return PAIRABLE_TYPES.includes(assetTypeMappingToString[assetType]);
}
