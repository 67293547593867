<p-table
  #table
  [value]="list().items"
  [loading]="list().loading"
  [totalRecords]="list().totalRecords"
  [rows]="list().visibleRows"
  [(first)]="list().first"
  [sortField]="list().sortField"
  [sortOrder]="list().sortOrder"
  [filters]="list().filters"
  [columns]="visibleColumns()"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="true"
  [alwaysShowPaginator]="false"
  paginatorPosition="bottom"
  [resetPageOnSort]="false"
  [reorderableColumns]="true"
  [dataKey]="dataKey()"
  [tableStyle]="tableStyle()"
  [scrollable]="scrollable()"
  [scrollHeight]="scrollHeight()"
  breakpoint="0"
  (onSort)="handleOnSort($event)"
  (onColReorder)="handleOnColReorder($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      @for (column of columns; track column) {
        <th [ngClass]="[column]" [ngStyle]="getColumnStyle(column)" pReorderableColumn>
          @if (getColumn(column); as columnDef) {
            <ng-container
              *ngTemplateOutlet="
                headerTemplateRef || defaultHeaderTemplate;
                context: { $implicit: columnDef }
              "
            ></ng-container>
          }
        </th>
      }
      <th class="actions immovable">
        <app-table-options-menu
          [list]="list()"
          [columns]="optionalColumns()"
          [visibleColumns]="columns"
          (applySettings)="onApplySettings($event)"
          (resetSettings)="onResetSettings()"
        >
        </app-table-options-menu>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-columns="columns">
    <tr>
      @for (column of columns; track column) {
        <td [ngClass]="[column]">
          @if (getColumn(column); as columnDef) {
            <ng-container
              *ngTemplateOutlet="
                cellTemplateRef || defaultCellTemplate;
                context: { $implicit: columnDef, row: row, rowIndex: rowIndex }
              "
            ></ng-container>
          }
        </td>
      }
      <td class="actions">
        <ng-container
          *ngTemplateOutlet="
            cellTemplateRef || defaultCellTemplate;
            context: { $implicit: { field: 'actions' }, row: row, rowIndex: rowIndex }
          "
        ></ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="visibleColumns().length + 1" class="empty-table-message">
        <app-lazy-load-table-messages
          [listContext]="list()"
          noDataMsg="{{ noDataMsg() || 'table.message.no-data' | translate }}"
          nofilteredDataMsg="{{
            nofilteredDataMsg() || 'table.message.all-entries-filtered-out' | translate
          }}"
          errorMsg="{{ errorMsg() || 'table.message.load-error' | translate }}"
        ></app-lazy-load-table-messages>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- Default template for the header cells if template with '#headerTemplate' is not provieded  -->
<ng-template #defaultHeaderTemplate let-column>
  <app-table-header
    label="{{ column.label | translate }}"
    [field]="column.field"
    [type]="column.type"
    [filter]="column.filterSignal ? column.filterSignal() : column.filter"
  ></app-table-header>
</ng-template>

<!-- Default template for the body cells if template with '#cellTemplate' is not provieded  -->
<ng-template #defaultCellTemplate let-column let-row="row">
  @switch (column.type) {
    @case ('date') {
      {{ row[column.field] | localizedDate: 'mediumDate' }}
    }
    @case ('number') {
      @if (column.filterLabels) {
        {{ row[column.field] | translate }}
      } @else {
        {{ row[column.field] }}
      }
    }
    @default {
      {{ row[column.field] }}
    }
  }
</ng-template>
