import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-no-elements-placeholder',
  templateUrl: './no-elements-placeholder.component.html',
  styleUrls: ['./no-elements-placeholder.component.scss'],
  standalone: true,
  imports: [NgIf, IconComponent],
})
export class NoElementsPlaceholderComponent {
  @Input() icon = 'no_sim';
  @Input() text: string;
}
